import {useAppDispatch} from '../../utils/customHooks';
import {fileAddTC, paymentFileAddTC} from '../../store/visaPageReducer';
import FileInput from '../../shared/FileInput/FileInput';

type PropsType = {
    images: Array<string>
    setImages: (value: Array<string>) => void
}

export const PaymentInput = ({images, setImages}: PropsType) => {
    const dispatch = useAppDispatch()


    const onChangeHandler = async (files: FileList | null) => {
        let arr: Array<string>
        if (files) {
            let res = await dispatch(paymentFileAddTC({files}));
            arr = res.payload as Array<string>
            setImages(arr)
        }

    }
    const resetHandler = () => {
        setImages([])
    }

    return (
        <div>
            <div className="">
                <div
                    className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                    <div className="space-y-1 text-center">
                        <svg
                            className="mx-auto h-12 w-12 text-neutral-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                        >
                            <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                        </svg>
                        <div className="flex flex-col text-sm text-neutral-6000 dark:text-neutral-300">
                            <label
                                htmlFor={'payment'}
                                className="relative cursor-pointer mx-auto rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                            >
                                {images.length !== 0
                                    ? <>
                                        <span>Вы загрузили {images.length} файл (-ов)</span>

                                    </>
                                    : <>
                                        <span>Загрузить файл</span>
                                    </>}
                                <input
                                    id={'payment'}
                                    type="file"
                                    className="sr-only"
                                    multiple
                                    onChange={(e) => {
                                        onChangeHandler(e.currentTarget.files)
                                    }
                                    }
                                />
                            </label>
                            {images.length !== 0 &&
                                <p className="cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                   onClick={() => {
                                       resetHandler()
                                   }}>
                                    Очистить
                                </p>}
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                            {'PNG, PDF, JPG, GIF'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileInput;