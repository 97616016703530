import {PencilSquareIcon} from "@heroicons/react/24/outline";
import React, {FC} from "react";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import ModalSelectDate from "components/ModalSelectDate";
import ModalSelectGuests from "components/ModalSelectGuests";
import {useAppDispatch, useAppSelector} from "../../utils/customHooks";
import CheckoutForm from "./CheckoutForm";
import { setServiceFieldsAC} from "../../store/appReducer";
import moment from "moment";

export interface CheckOutPageProps {
  className?: string;
}

const CheckOutPage: FC<CheckOutPageProps> = ({className = ""}) => {
  const dispatch = useAppDispatch()
  const {service} = useAppSelector(state => state.app)


  const renderSidebar = () => {
    return (
      <div
        className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage
                src="https://images.pexels.com/photos/6373478/pexels-photo-6373478.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"/>
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                Hotel room in Tokyo, Jappan
              </span>
              <span className="text-base font-medium mt-1 block">
                The Lounge & Bar
              </span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              2 beds · 2 baths
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            <StartRating/>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>$19 x 3 day</span>
            <span>$57</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>$0</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>$57</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return service.service_name
      ? (
        <div
          className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
          <h2 className="text-3xl lg:text-4xl font-semibold">
            Запрос на услугу
          </h2>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div>
            <div>
              <h4 className="text-xl font-semibold text-neutral-700 dark:text-neutral-400">Ваша услуга:</h4>
              <h3 className="text-2xl font-semibold pt-2">{service.service_name}</h3>
            </div>
            <div
              className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
              <ModalSelectDate
                defaultValue={service.date}
                onSelectDate={(date) => dispatch(setServiceFieldsAC({date}))}
                renderChildren={({openModal}) => (
                  <button
                    onClick={openModal}
                    className="text-left flex-1 p-5 flex justify-between space-x-5 "
                    type="button"
                  >
                    <div className="flex flex-col">
                      <span className="text-sm text-neutral-400">Дата</span>
                      <span className="mt-1.5 text-lg font-semibold">
                {service?.date ? moment(service?.date).format('DD MMM') : 'Выберите дату'}
                    </span>
                    </div>
                    <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400"/>
                  </button>
                )}
              />

              <ModalSelectGuests
                defaultValue={service.guests}
                onChangeGuests={(guests) => dispatch(setServiceFieldsAC({guests}))}
                renderChildren={({openModal}) => (
                  <button
                    type="button"
                    onClick={openModal}
                    className="text-left flex-1 p-5 flex justify-between space-x-5"

                  >
                    <div className="flex flex-col">
                      <span className="text-sm text-neutral-400">Гости</span>
                      <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {`${
                          (service.guests.guestAdults || 0) +
                          (service.guests.guestChildren || 0) +
                          (service.guests.guestInfants || 0)
                        } Гостей`}
                      </span>
                    </span>
                    </div>
                    <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400"/>
                  </button>
                )}
              />
            </div>
          </div>

          <div>
            <h3 className="text-2xl font-semibold">Ваши данные</h3>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
            <CheckoutForm/>
          </div>
        </div>
      ) : (
        <></>
      )
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex justify-center">
        <div className="w-full lg:w-2/3">{renderMain()}</div>
      </main>
    </div>
  );
};

export default CheckOutPage;
