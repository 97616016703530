import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {getVisaTC} from '../../../store/visaCheckReducer';
import {accountingSuccessTC, clearState} from '../../../store/visaPageReducer';
import {useAppDispatch, useAppSelector} from '../../../utils/customHooks';
import {PaymentInput} from '../PaymentInput';
import ButtonPrimary from '../../../shared/Button/ButtonPrimary';
import {useNavigate, useParams} from 'react-router-dom';
import completeIcon from '../../../images/complete_icon.svg';

const PageUploadReceipt = () => {
    const {fullPrice, email, visa_status, numberOfApplicants} = useAppSelector(state => state.visa_page)
    const {uid} = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [imagesArr, setImagesArr] = useState<Array<string>>([])
    const [usdSgdRate, setUsdSgdRate] = useState<number>(0)
    const successHandler = async () => {
        let res = await dispatch(accountingSuccessTC({
            uid,
            email,
            name: 'Stripe payment',
            rates: {
                usdRubRate: 0,
                usdSgdRate
            },
            currency: 'SGD',
            value: Math.ceil(fullPrice * usdSgdRate * 1.035),
            fullPrice: 'SGD' + Math.ceil(fullPrice * usdSgdRate * 1.035),
            imagesArr,
            paymentType: 'Stripe',
            numberOfApplicants
        }))
        if (res.payload) {
            navigate('/payment_success')
        }
    }

    useEffect(() => {
        axios.get('https://openexchangerates.org/api/latest.json?app_id=eefaec644baf4069b485ed42d36857f7&base=USD&symbols=RUB,SGD,THB').then(res => {
            if (res.data.timestamp) {
                setUsdSgdRate(
                  res.data.rates.SGD
                )
            }
        })
        uid && dispatch(getVisaTC(uid))
        return () => {
            dispatch(clearState())
        }
    }, [])

    if(visa_status !== 'ожидает оплаты'){
        return <></>
    }

    return (
        <div className="w-full flex flex-col items-center space-y-8 p-6 xl:p-8">
            <header className="text-center mx-auto mt-10">
                <h2 className="flex items-center text-xl leading-[115%] md:text-3xl md:leading-[115%] xl:text-5xl font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Загрузите скриншот чека оплаты
                    <img src={completeIcon} alt={'complete'} className="ml-3" width={30}/>
                </h2>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                      Чек был отправлен вам на e-mail.
                      </span>
            </header>
            <div
                className={'flex flex-col gap-5 text-center w-1/2'}>
                <PaymentInput images={imagesArr} setImages={setImagesArr}/>
                <ButtonPrimary
                    onClick={successHandler}
                    className={'md:w-[200px] md:self-end'}
                >
                    Отправить
                </ButtonPrimary>
            </div>
        </div>
    );
};

export default PageUploadReceipt;