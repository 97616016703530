import React, {useState} from 'react';
import Heading2 from '../Heading/Heading2';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';
import {useNavigate} from 'react-router-dom';
import {getVisaTC} from '../../store/visaCheckReducer';
import {useAppDispatch} from '../../utils/customHooks';
import {Helmet} from 'react-helmet';
import {VisaPageDataType} from '../../store/visaPageReducer';

const VisaCheckPage = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const [id, setId] = useState('')

    const onClickHandler = async () => {
        let res = await dispatch(getVisaTC(id)) as { payload: VisaPageDataType }
        if (res.payload) {
            navigate('/check_result/' + res.payload.uid)
        }
    }

    return (
        <div className={'container py-10 pb-24 lg:pb-28'}>
            <Helmet>
                <title>Статус заявки</title>
            </Helmet>
            <Heading2
                heading={'Проверка статуса Вашей визы'}
                subHeading={'Для проверки статуса визы необходимо ввести уникальный номер заявки, присланный Вам при оформлении на email.'}
            />
            <div className={'flex flex-col justify-center max-w-[320px] gap-3 mx-auto'}>
                <label className={'font-semibold'}>
                    Введите уникальный номер заявки:
                    <input className={'mt-3'} required type="text" value={id} onChange={(e) => {
                            setId(e.currentTarget.value)
                    }}/>
                </label>
                <ButtonPrimary onClick={onClickHandler} disabled={id.length === 0}>Проверить</ButtonPrimary>
            </div>
        </div>
    );
};

export default VisaCheckPage;