import React, {FC, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../utils/customHooks';
import ButtonPrimary from "../../../shared/Button/ButtonPrimary";
import completeIcon from '../../../images/complete_icon.svg'
import {useNavigate} from 'react-router-dom';
import {clearState} from '../../../store/visaPageReducer';

export interface VisaCompleteProps {
    className?: string;
}

const VisaComplete: FC<VisaCompleteProps> = ({className = ""}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {
        uid,
        email,
        fullPrice
    } = useAppSelector(state => state.visa_page)

    useEffect(() => {
        return () => {
            dispatch(clearState());
        };
    }, []);

    const renderContent = () => {
        return (
            <div
                className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                <div className='flex justify-center'>
                    <h3 className="text-3xl lg:text-4xl text-center">
                        Ваша заявка отправлена
                    </h3>
                    <img src={completeIcon} alt={'complete'} className='ml-3' width={30}/>
                </div>

                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

                {/* ------------------------ */}
                <div className="space-y-6">
                    <h3 className="text-2xl font-semibold">Детали заказа</h3>
                    <div className="flex flex-col space-y-4">
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                            <span className="flex-1">Уникальный номер заявки:</span>
                            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {uid}
              </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                            <span className="flex-1">Ваш email</span>
                            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {email}
              </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                            <span className="flex-1">Предварительная стоимость:</span>
                            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {fullPrice} USD
              </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
            <main className="container mt-11 mb-24 lg:mb-32 ">
                <div className="max-w-4xl mx-auto">{renderContent()}</div>
            </main>
        </div>
    );
};

export default VisaComplete;