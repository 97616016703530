import React from 'react';
import {FC} from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';

export interface CommonLayoutProps {
    index: string;
    nextHref?: string;
    backtHref?: string;
    nextBtnText?: string;
    isDisabled?: boolean;
    children: React.ReactNode;
    type?: {}
}

const CommonLayout: FC<CommonLayoutProps> = ({
                                                 type,
                                                 children,
                                                 nextHref,
                                                 nextBtnText,
                                                 backtHref,
                                                 isDisabled,
                                             }) => {
    return (
        <div className={'container'}>
            <div className={'font-normal pt-8 text-center text-4xl md:text-4xl xl:text-5xl !leading-[114%]'}> Визовая
                Анкета
            </div>
            <div
                className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-10 sm:py-10 lg:pb-32`}
                data-nc-id="PageAddListing1"
            >
                <div className="h-1000px">
                    {/* --------------------- */}
                    <div>{children}</div>

                    {/* --------------------- */}
                    <div className="flex justify-end space-x-5">
                        {backtHref && <ButtonSecondary href={backtHref}>Назад</ButtonSecondary>}
                        {type && <ButtonPrimary type="submit" disabled={isDisabled}>
                            {nextBtnText || 'Продолжить'}
                        </ButtonPrimary>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommonLayout;
