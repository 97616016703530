import React, {FC, useState} from 'react';
import ExperiencesCard from 'components/ExperiencesCard/ExperiencesCard';
import cityUrl from '../../images/cityTour.webp'
import city2Url from '../../images/city2.webp'
import evening1Url from '../../images/eveningTour.webp'
import evening2Url from '../../images/evening2.webp'
import evening3Url from '../../images/evening3.webp'
import singapore from "../../images/cityTour/singapore-city-state.jpg"
import museum from "../../images/cityTour/museum-singapore.jpeg"
import merlion from "../../images/cityTour/singapore_merlion.jpeg"
import raffles from "../../images/cityTour/stamford-raffles-singapore.jpeg"
import victoria from "../../images/cityTour/theater-victoria.jpeg"
import chinatown from "../../images/cityTour/singapore_chinatown.jpeg"


export interface SectionGridFilterCardProps {
    className?: string;
    data?: ServicesType[];
}


export type ServicesType = {
    id: number | string,
    galleryImgs: Array<string>
    address: string
    title: string
    category: 'hotel' | 'tour' | 'transfer'
    href?: string
    description: Array<{
        boldText?: string,
        text?: string,
        list?: string,
        br?: boolean,
    }>
    label?: string
    duration?: string
    maxPeople?: string
    language?: string
    includes: Array<{ name: string }>
    image: Array<string>,
    cancelPolicy?: string,
    conditions?: string,
    whatToBring?: Array<string>
}

export let servicesData: Array<ServicesType> = [
    /* {
         id: 1,
         galleryImgs: [
             eliz1Url,
             eliz2Url,
         ],
         address: '24 Mount Elizabeth, Сингапур',
         title: 'Hotel Elizabeth 4 звезды',
         category: 'hotel',
         includes: [],
         description: [],
         image: []
     },
     {
         id: 2,
         galleryImgs: [
             boss1Url,
             boss2Url,
             boss3Url
         ],
         address: '500 Jalan Sultan Road, Сингапур',
         title: 'Hotel Boss 3 звезды',
         category: 'hotel',
         includes: [],
         description: [],
         image: []
     },
     {
         id: 3,
         galleryImgs: [
             bay1Url,
             bay2Url,
             bay3Url
         ],
         address: '10 Bayfront Avenue, Сингапур',
         title: 'Marina Bay Sands 5 звезд',
         category: 'hotel',
         includes: [],
         description: [],
         image: []
     },*/
    {
        id: 'city-tour',
        galleryImgs: [
            city2Url,
            cityUrl,
        ],
        address: '',
        title: 'Груповая экскурсия по Сингапуру',
        duration: '3.5 часа',
        maxPeople: 'До 40 человек',
        language: 'Русский гид',
        label: 'Экскурсии',
        category: 'tour',
        href: '/tour/city-tour',
        cancelPolicy: 'Тур может быть отменен с возмещением стоимости за исключением комиссии за перевод в ' +
          'течение 24 часов с момента покупки, но не позже чем за три для до начала тура\n',
        conditions: 'В обзорном туре могут принять участие до 40 человек.',
        whatToBring: ['Фото - видео технику', 'Деньги на мелкие расходы и сувениры', 'Солнце защитные средства'],
        includes: [
            {name: "Комфортабельный транспорт"},
            {name: "Русский гид"},
            {name: "Экскурсионная программа"},
            {name: "Обед"},
        ],
        description: [
            {boldText: '14:00 – ', text: 'Обед в ресторане.'},
            {br: true},
            {list: 'Обед включает свежие салаты, закуски (спринг-роллы и самоса) и овощной суп. Основные блюда представлены жареным рисом, лапшой, куриными крылышками, овощами, запеченным картофелем, курицей карри и рыбой в кисло-сладком соусе. На десерт — свежие фрукты и желе, а также напитки: манговый и лаймовый.'},
            {br: true},
            {
                list: 'Начало обзорной экскурсии по Сингапуру. Гид расскажет вам все об острове, государстве\n' +
                  'и городе, о его прошлом и настоящем.'
            },
            {br: true},
            {
                list: 'Посещение фонтана богатства - открытом в 1995 году, который находится на территории крупного ' +
                  'коммерческого центра Suntec City, сконструированный в строжайшем соответствии с учением фен-шуй: ' +
                  'пять зданий центра расположены в виде пяти пальцев ладони левой руки с загнутыми пальцами, а в центре ' +
                  'ладони находится фонтан. '
            },
            {br: true},
            {
                list: 'Паданг (проездом)- открытое игровое поле, расположенное в деловом центре Сингапура. ' +
                  'Место проведения различных важных мероприятий: праздников, парадов и даже церемонии открытия ' +
                  'Летних юношеских олимпийских игр 2010.'
            },
            {br: true},
            {
                list: 'Обзор театра Эспланада (снаружи), который находится на знаменитой городской набережной ' +
                  'Марина Бэй и является не просто шедевром архитектуры, но и международным центром искусства.'
            },
            {br: true},
            {
                list: 'Памятник Стэмфорду Раффлзу, знаменитому основателю современного Сингапура, расположенный на ' +
                  'набережной реки Сингапур. На месте, где этот британский государственный деятель впервые высадился ' +
                  'на Сингапурской земле. '
            },
            {br: true},
            {
                list: 'Мост Кавенах - Самый старый мост в городе. Его построили в Шотландии в 1868 году, после\n' +
                  'чего был разобран и послан в Сингапур, где был собран снова.'
            },
            {br: true},
            {
                list: 'Осмотр реки Сингапур, где по преданию, возле реки принц Санг Нила Утама встретил льва,\n' +
                  'откуда пошло название острова, реки и города — синга пура, «город льва».'
            },
            {br: true},
            {
                list: 'Театр Виктории(снаружи) - Еще одно колониальное наследие Сингапура, здание принадлежит ' +
                  'симфоническому оркестру, театр Виктории. Старинная башенка с часами очень красиво смотрится ' +
                  'на фоне новых небоскребов.'
            },
            {br: true},
            {
                list: 'Обзор делового района Сингапура – район небоскрёбов, в котором сосредоточен практически весь ' +
                  'бизнес Сингапура.'
            },
            {br: true},
            {
                list: 'Посещение отеля Фуллертон - здание колониального периода, выстроенное в неоклассическом стиле. ' +
                  'Постройка была символом всей западной цивилизации в Сингапуре — «воротах Азии».'
            },
            {br: true},
            {
                list: 'Посещение парка Мерлион - сердце и главная достопримечательность Сингапура – статуя Мерлиона ' +
                  'высотой восемь метров и весом семьдесят тонн, установленная в 1972 году. Эмблема Сингапура и его талисман'
            },
            {br: true},
            {
                list: 'Прогулка по Чайна-таун - лабиринты улиц с деревянными домами, антикварные и чайные лавки, ' +
                  '“офисы” магов и астрологов, десятки кафе под открытым небом – это все Чайна-таун, исторический ' +
                  'центр Сингапура, большинство жителей которого являются этническими китайцами.'
            },
            {br: true},
            {list: 'Храм божественного блаженства, посвященного двум святыням -Даосской богине Мацзу, и Буддистской ' +
                  'богине Гуаньинь или Храм Зуба Будды, который посвящен важной буддистской реликвии — Священному Зубу ' +
                  'Будды, оставшемуся после кремации тела священного Гаутамы и олицетворяющему живого Будду и его ' +
                  'власть над миром (посещение зависит от религиозных праздников и времени приношений).'},
            {br: true},
            {list: 'Улица Орчард Роуд (проездом)— это самая известная среди шопоголиков всего мира улица, центр ' +
                  'торговли и развлечений, одна из главных туристических достопримечательностей Сингапура. ' +
                  'На всем протяжении бульвара расположены бесконечные ряды торгово-развлекательных центров, ' +
                  'ресторанов и гостиниц.'},
            {br: true},
        ],
        image: [singapore,
            museum,
            merlion,
            raffles,
            victoria,
            chinatown]
    },
    {
        id: 'individual-city-tour',
        galleryImgs: [
            singapore,
            merlion,
        ],
        address: '',
        title: 'Индивидуальная обзорная экскурсия по Сингапуру',
        duration: '3.5 часа',
        maxPeople: 'До 10 человек',
        language: 'Русский гид',
        label: 'Экскурсии',
        category: 'tour',
        href: '/tour/individual-city-tour',
        cancelPolicy: 'Тур может быть отменен с возмещением стоимости за исключением комиссии за перевод в ' +
          'течение 24 часов с момента покупки, но не позже чем за три для до начала тура\n',
        conditions: 'В обзорном туре могут принять участие до 10 человек.',
        whatToBring: ['Фото - видео технику', 'Деньги на мелкие расходы и сувениры', 'Солнце защитные средства'],
        includes: [
            {name: "Комфортабельный транспорт"},
            {name: "Русский гид"},
            {name: "Экскурсионная программа"},
            {name: "Трансфер в отель"},
        ],
        description: [
            {boldText: '11:00 – ', text: 'Встреча с русским гидом в лобби отеля.'},
            {
                list: 'Начало обзорной экскурсии по Сингапуру. Гид расскажет вам все об острове, государстве\n' +
                  'и городе, о его прошлом и настоящем.'
            },
            {
                list: 'Посещение Отеля Раффлз — исторический отель, построенный в 1887 году в классическом\n' +
                  'колониальном стиле и назван в честь основателя Сингапура Сэра Стэмфорда Раффлза.'
            },
            {
                list: 'Посещение фонтана богатства - открытом в 1995 году, который находится на территории\n' +
                  'крупного коммерческого центра Suntec City, сконструированный в строжайшем соответствии с\n' +
                  'учением фен-шуй: пять зданий центра расположены в виде пяти пальцев ладони левой руки с\n' +
                  'загнутыми пальцами, а в центре ладони находится фонтан.'
            },
            {
                list: 'Паданг - открытое игровое поле, расположенное в деловом центре Сингапура. Место\n' +
                  'проведения различных важных мероприятий: праздников, парадов и даже церемонии открытия\n' +
                  'Летних юношеских олимпийских игр 2010.'
            },
            {
                list: 'Обзор театра Эспланада, который находится на знаменитой городской набережной Марина\n' +
                  'Бэй и является не просто шедевром архитектуры, но и международным центром искусства.'
            },
            {
                list: 'Памятник Стэмфорду Раффлзу, знаменитому основателю современного Сингапура,\n' +
                  'расположенный на набережной реки Сингапур. На месте, где этот британский государственный\n' +
                  'деятель впервые высадился на Сингапурсккой земле.'
            },
            {
                list: 'Мост Кавенах -Самый старый мост в городе. Его построили в Шотландии в 1868 году, после\n' +
                  'чего был разобран и послан в Сингапур, где был собран снова.'
            },
            {
                list: 'Осмотр реки Сингапур, где по преданию, возле реки принц Санг Нила Утама встретил льва,\n' +
                  'откуда пошло название острова, реки и города — синга пура, «город льва».'
            },
            {
                list: 'Обзор делового района Сингапура – район небоскрёбов, в котором сосредоточен практически\n' +
                  'весь бизнес Сингапура.'
            },
            {
                list: 'Посещение отеля Фуллертон - здание колониального периода, выстроенное в\n' +
                  'неоклассическом стиле. Постройка была символом всей западной цивилизации в Сингапуре —\n' +
                  '«воротах Азии».'
            },
            {
                list: 'Посещение парка Мерлион - сердце и главная достопримечательность Сингапура – статуя\n' +
                  'Мерлиона высотой восемь метров и весом семьдесят тонн, установленная в 1972 году.\n' +
                  'Эмблема Сингапура и его талисман.'
            },
            {
                list: 'Прогулка по Чайна-таун - лабиринты улиц с деревянными домами, антикварные и чайные\n' +
                  'лавки, “офисы” магов и астрологов, десятки кафе под открытым небом – это все Чайна-таун,\n' +
                  'исторический центр Сингапура, большинство жителей которого, являются этническими\n' +
                  'китайцами.'
            },
            {
                list: 'Храм божественного блаженства — старейший китайский храмовый комплекс в Сингапуре,\n' +
                  'расположенный на территории Чайна-тауна. Он состоит из двух храмов — главного,\n' +
                  'даосистского, посвященного богине Мацзу, и буддистского, посвященного богине Гуаньинь.'
            },
            {
                list: 'Улица Орчард Роуд — это самая известная среди шопоголиков всего мира улица, центр\n' +
                  'торговли и развлечений, одна из главных туристических достопримечательностей Сингапура.\n' +
                  'На всем протяжении бульвара расположены бесконечные ряды торгово-развлекательных\n' +
                  'центров, ресторанов и гостиниц.'
            },
            {boldText: '15:00 – ', text: 'Трансфер в отель'},
        ],
        image: [singapore,
            museum,
            merlion,
            raffles,
            victoria,
            chinatown]
    },
    /*  {
          id: 5,
          galleryImgs: [
              sentosa1Url,
              sentosa2Url
          ],
          address: '',
          title: 'Экскурсия «Остров Сентоза»',
          category: 'tour',
          includes: [],
          description: [],
          image: []
      },*/
    {
        id: 'night-tour',
        href: '/tour/night-tour',
        galleryImgs: [
            evening1Url,
            evening2Url,
            evening3Url
        ],
        address: '',
        title: 'Экскурсия «Вечерний Сингапур»',
        category: 'tour',
        duration: '4 часа',
        maxPeople: 'До 10 человек',
        language: 'Русский гид',
        label: 'Экскурсии',
        cancelPolicy: 'Тур может быть отменен с возмещением стоимости за исключением комиссии за перевод в ' +
          'течение 24 часов с момента покупки, но не позже чем за три для до начала тура\n',
        includes: [
            {name: "Комфортабельный транспорт"},
            {name: "Русский гид"},
            {name: "Экскурсионная программа"},
            {name: "Трансфер в отель"},
        ],
        description: [
            {boldText: '19:00 – ', text: 'Встреча с русским гидом в лобби отеля, и трансфер в сады у залива.'},
            {
                boldText: '19:45 – ',
                text: 'Шоу садовая рапсодия. Каждый вечер в садах у залива возле супер деревьев проходит свето-музыкальное шоу садовая рапсодия (Garden Rhapsody). Шоу длится 10 минут, в течение которых деревья мигают и переливаются разноцветными огнями в такт музыки'
            },
            {
                boldText: '20:10 – ',
                text: 'Мост Хеликс (The Helix Bridge) считается одним из самых красивых мостов мира, чем привлекает множество туристов. Как и все, что строится в центре Сингапура, мост имеет уникальный дизайн и гармонично вписывается в необычную архитектуру этого города будущего. Мост был открыт в 2010 году и сразу высоко оценен архитекторами всего мира. Он является спиралевидным мостом, дизайн которого напоминает молекулу ДНК. Мост Хеликс соединяет Марина Центр с южной частью залива, на которой расположен знаменитый отель Marina Bay Sands и необычные сады Gardens by the Bay.'
            },
            {
                boldText: '21:00 – ',
                text: 'Водно-лазерное шоу Spectra проводится в Сингапуре ежедневно на площади вдоль набережной возле отеля Марина Бей Сендс. Прямо на воде установлены специальные фонтаны, которые будут создавать своеобразный экран, на котором перед вашими глазами будет разворачиваться история, под прекрасную симфоническую музыку.'
            },
            {
                boldText: '21:30 – ',
                text: 'Ужин в ресторане LeVeL 33 — это уникальная отмеченная наградами концепция ресторана на крыше самой высокогорной в мире городской мини-пивоварни. Насладитесь захватывающим видом на сингапурский залив Марина и городской пейзаж из финансового центра Marina Bay (Ужин не входит в стоимость тура)'
            },
            {
                boldText: '22:45 – ',
                text: 'Трансфер в отель с русским гидом.'
            },
        ],
        conditions: 'В обзорном туре могут принять участие до 10 человек..',
        whatToBring: ['Фото - видео технику', 'Деньги на мелкие расходы, сувениры и ужин в ресторане'],
        image: [  evening1Url,
            evening2Url,
            evening3Url]
    },
    /*{
        id: 7,
        galleryImgs: [
            safari1Url,
            safari2Url
        ],
        address: '',
        title: 'Аттракцион «Ночное сафари»',
        category: 'tour',
        includes: [],
        description: [],
        image: []
    },
    {
        id: 8,
        galleryImgs: [
            universal1Url,
            universal3Url,
            universal2Url,
        ],
        address: '',
        title: 'Аттракцион «Юниверсал Студио»',
        category: 'tour',
        includes: [],
        description: [],
        image: []
    },
    {
        id: 9,
        galleryImgs: [
            aqua1Url,
            aqua2Url,
            aqua3Url,
        ],
        address: '',
        title: 'Аттракцион «Аквапарк Сентоза»',
        category: 'tour',
        includes: [],
        description: [],
        image: []
    },
    {
        id: 10,
        galleryImgs: [
            car1Url,
            car2Url,
        ],
        address: '',
        title: 'Стандартный трансфер',
        category: 'transfer',
        includes: [],
        description: [],
        image: []
    },
    {
        id: 11,
        galleryImgs: [
            vip1Url,
            vip2Url,
        ],
        address: '',
        title: 'VIP трансфер',
        category: 'transfer',
        includes: [],
        description: [],
        image: []
    },*/
]


const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
                                                                   className = '',
                                                                   data = servicesData,
                                                               }) => {
    const [category, setCategory] = useState<'hotel' | 'tour' | 'transfer' | 'all'>('all');
    let filteredData = data.filter(el => {
        if (category === 'all') {
            return el
        } else {
            return el.category === category
        }
    })
    return (
      <section
        className={`nc-SectionGridFilterCard ${className}`}
        data-nc-id="SectionGridFilterCard"
      >
          <h1 className="text-4xl mb-5 font-semibold">Наши услуги</h1>

          <section className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
              {
                  filteredData.map((stay) => (
                    <ExperiencesCard key={stay.id} data={stay}/>
                  ))}
          </section>
      </section>
    );
};

export default SectionGridFilterCard;

