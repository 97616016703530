import React, {FC, Fragment, useState} from 'react';
import CommonLayout from '../CommonLayout';
import {Tab} from '@headlessui/react';
import VisaPage1Item from './VisaPage1Item';
import {useAppSelector} from '../../../utils/customHooks';

export interface VisaPage1Props {
}

const VisaPage1: FC<VisaPage1Props> = () => {
    const {uid, numberOfApplicants, applicantsData, } = useAppSelector(state => state.visa_page)
    let names = [...new Array(+numberOfApplicants)].map((el,index) => {
      return applicantsData[index]?.fullName ? applicantsData[index]?.fullName : `Заявитель ${index + 1}`
    })
    let [categories] = useState(names);
    const isPH = uid?.split('-')[0] === 'PH';


    return (
        <CommonLayout
            index="02"
        >
            <>
                <div>
                    <Tab.Group>
                        <Tab.List className="flex space-x-4 overflow-x-auto">
                            {categories.map((item) => (
                                <Tab key={item} as={Fragment}>
                                    {({selected}) => (
                                        <button
                                            className={`flex justify-center self-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${
                                                selected
                                                    ? 'border-primary-600 bg-primary-50 text-primary-700'
                                                    : 'border-neutral-300 dark:border-neutral-700'
                                            } `}
                                        >
                                            {item}
                                        </button>
                                    )}
                                </Tab>
                            ))}
                        </Tab.List>
                        <Tab.Panels>
                            {[...new Array(+numberOfApplicants)].map((el,index) => {
                                return (
                                    <Tab.Panel className="" key={index}>
                                        <VisaPage1Item index={index} isPH={isPH}/>
                                    </Tab.Panel>
                                )
                            })}
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </>
        </CommonLayout>
    )
        ;
};

export default VisaPage1;
