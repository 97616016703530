import React, {Fragment, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../utils/customHooks';
import {useNavigate, useParams} from 'react-router-dom';
import FotoUploadModal from '../../../containers/VisaPage/Modal/FotoUploadModal';
import CommonLayout from '../../../containers/VisaPage/CommonLayout';
import {Tab} from '@headlessui/react';
import FileInput from '../../../shared/FileInput/FileInput';
import Button from '../../../shared/Button/Button';
import {clearState, filedCheckTC, getVisaForBadPhotoTC, sendBadPhotosCompleteTC} from '../../../store/visaPageReducer';

const EditPhotoPage = () => {
    const {visa_status, badPhotos} = useAppSelector(state => state.visa_page)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {uid} = useParams()


    useEffect(() => {
        uid && dispatch(getVisaForBadPhotoTC(uid))
        return () => {
            dispatch(clearState())
        }
    }, []);

    if(visa_status !== 'плохое фото'){
        return <></>
    }

    const onSuccessHandler = async () => {
        let checkRes = await dispatch(filedCheckTC({photoFiles: ['123']}))
        if(checkRes.payload){
            await dispatch(sendBadPhotosCompleteTC())
            navigate('/edit_success')
        }
    }

    return (
        <CommonLayout
            index="05"
        >
            <>
                <div className={'flex flex-col'}>
                    <div className='mb-4'>
                        <h2 className="text-2xl font-semibold">Загрузите новое фото на визу</h2>
                    </div>
                    <FotoUploadModal initialState={true}/>
                    <Tab.Group>
                        <Tab.List className="flex space-x-4 overflow-x-auto">
                            {badPhotos && badPhotos.map(({fullName,isChecked}) => (
                                isChecked && <Tab key={fullName} as={Fragment}>
                                    {({selected}) => (
                                        <button
                                            className={`flex justify-center self-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${
                                                selected
                                                    ? "border-primary-600 bg-primary-50 text-primary-700"
                                                    : "border-neutral-300 dark:border-neutral-700"
                                            } `}
                                        >
                                            {fullName}
                                        </button>
                                    )}
                                </Tab>
                            ))}
                        </Tab.List>
                        <Tab.Panels>
                            <Tab.Panels>
                                {badPhotos && badPhotos.map((el,index) => {
                                    return (
                                        el.isChecked && <Tab.Panel className="" key={index}>
                                                <div className="mb-4 gap-8 space-y-8">
                                                    <FileInput fieldName={'photoFiles'} title={''} index={index} types={'image/png, image/jpeg, image/jpg'} typesName={'PNG, JPG, JPEG'}/>
                                                </div>
                                        </Tab.Panel>
                                    )
                                })}
                            </Tab.Panels>
                        </Tab.Panels>
                    </Tab.Group>
                    <Button
                        className={'ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${className} dark:text-neutral-200 self-end'}
                        onClick={onSuccessHandler}
                    >Отправить
                    </Button>
                </div>
            </>
        </CommonLayout>
    );
};

export default EditPhotoPage;