import React from 'react';
import {useAppSelector} from '../../utils/customHooks';

const LoadingScreen = () => {
    const {appStatus} = useAppSelector(state => state.app)
    let sendStyle = appStatus === 'loading' ? 'loader_wrapper w-full fixed z-50 inset-0' : 'hidden';

    return (
        <div className={sendStyle}>
            <div className="loader"></div>
        </div>
    );
};

export default LoadingScreen;