import React from 'react';
import Label from '../../../components/Label/Label';
import Input from '../../../shared/Input/Input';
import Select from '../../../shared/Select/Select';
import FormItem from '../FormItem';
import {useAppDispatch, useAppSelector} from '../../../utils/customHooks';
import {useNavigate} from 'react-router-dom';
import {filedCheckTC, updateApplicantField} from '../../../store/visaPageReducer';
import {useFormik} from 'formik';
import Button from '../../../shared/Button/Button';
import Checkbox from '../../../shared/Checkbox/Checkbox';


type FormikErrorType = {
    residenceCountry?: string
    residenceCity?: string
    residenceAddress?: string
    residenceIndex?: string
}

const VisaPage4Item = ({index,isPH}: { index: number, isPH?:boolean }) => {
    const {
        residenceCountry,
        residenceCity,
        residenceAddress,
        residenceIndex
    } = useAppSelector(state => state.visa_page.applicantsData[index])
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const applicantData = useAppSelector(state => state.visa_page.applicantsData[0])
    const residence = (({residenceCountry, residenceCity, residenceAddress, residenceIndex}) => ({
        residenceCountry,
        residenceCity,
        residenceAddress,
        residenceIndex
    }))(applicantData)

    const onBlurHandler = (fieldName: string, value: string, e: React.FocusEvent<HTMLInputElement | HTMLSelectElement, Element>) => {
        dispatch(updateApplicantField({fieldName, value, index}));
        formik.handleBlur(e)
    }

    const setSameResidence = () => {
        formik.setValues(residence)
        for (const key in residence) {
            dispatch(updateApplicantField({
                fieldName: key,
                value: residence[key as keyof typeof residence],
                index
            }))
        }
    }

  const formik = useFormik({
    validate: (values) => {
      const errors: FormikErrorType = {}
      if (!values.residenceCountry) {
        errors.residenceCountry = 'Укажите страну'
      }
      if (!values.residenceCity) {
        errors.residenceCity = 'Укажите город'
      } else if (
        /[А-яЁё]/i.test(values.residenceCity)) {
        errors.residenceCity = 'Используйте латинский алфавит'
      }
      if (!values.residenceAddress) {
        errors.residenceAddress = 'Укажите адрес'
      } else if (
        /[А-яЁё]/i.test(values.residenceAddress)) {
        errors.residenceAddress = 'Используйте латинский алфавит'
      }
      if (!values.residenceIndex) {
        errors.residenceIndex = 'Укажите индекс'
      }
      return errors
    },
    initialValues: {
      residenceCountry,
      residenceCity,
      residenceAddress,
      residenceIndex,
    } as FormikErrorType,
    onSubmit: async (values: any) => {
      let res = await dispatch(filedCheckTC(values))
      if (res.payload) {
        navigate('/visa5')
      }
    }
  })
  return (
    <form className="listingSection__wrap mt-6 mb-4" onSubmit={formik.handleSubmit} autoComplete="none">
      <h2 className={'font-semibold text-xl text-neutral-700 dark:text-neutral-300 '}>Адрес места жительтва по
        прописке</h2>
      {index > 0 &&
          <Checkbox onChange={setSameResidence} name="copy residence" label="Скопировать у основного заявителя"
                    className="mt-4"/>}
      <FormItem>
        <div className="mt-4">
          <Label>Страна</Label>
          <Select className="mt-4"
                  {...formik.getFieldProps('residenceCountry')}
                  onBlur={(e) => {
                    onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                  }}
          >
            <option value="">Выберите страну</option>
            <option value="Россия">Россия</option>
            <option value="Армения">Армения</option>
            <option value="Азербайджан">Азербайджан</option>
            <option value="Беларусь">Беларусь</option>
            <option value="Грузия">Грузия</option>
            <option value="Казахстан">Казахстан</option>
            <option value="Киргизстан">Киргизстан</option>
            <option value="Молдова">Молдова</option>
            <option value="Таджикистан">Таджикистан</option>
            <option value="Туркменистан">Туркменистан</option>
            <option value="Узбекистан">Узбекистан</option>
            <option value="Не гражданин Латвии">Негражданин Латвии</option>
            <option value="Не гражданин Эстонии">Негражданин Эстонии</option>
          </Select>
          {formik.errors.residenceCountry && formik.touched.residenceCountry ?
            <div className="text-rose-600">{formik.errors.residenceCountry}</div> : null}
        </div>
        <div className="mt-4">
          <Label>Город</Label>
          <Input
            className="mt-4"
            type="text"
            placeholder={'Город'}
            {...formik.getFieldProps('residenceCity')}
            autoComplete={'none'}
            onBlur={(e) => {
              onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
            }}
          />
          {formik.errors.residenceCity && formik.touched.residenceCity ?
            <div className="text-rose-600">{formik.errors.residenceCity}</div> : null}
        </div>
        <div className="mt-4">
          <Label>Адрес</Label>
          <Input
            className="mt-4"
            type="text"
            placeholder="Улица, дом, квартира"
            {...formik.getFieldProps('residenceAddress')}
            autoComplete={'none'}
            onBlur={(e) => {
              onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
            }}
          />
          {formik.errors.residenceAddress && formik.touched.residenceAddress ?
            <div className="text-rose-600">{formik.errors.residenceAddress}</div> : null}
        </div>
        <div className="mt-4">
          <Label>Индекс</Label>
          <Input
            className="mt-4"
            type="text"
            placeholder="220120"
            {...formik.getFieldProps('residenceIndex')}
            onBlur={(e) => {
              onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
            }}
          />
          {formik.errors.residenceIndex && formik.touched.residenceIndex ?
            <div className="text-rose-600">{formik.errors.residenceIndex}</div> : null}
        </div>
      </FormItem>
      <div className="flex justify-end space-x-5 mt-8">
        <Button
          className={`ttnc-ButtonSecondary font-medium border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800`}
          href={'/visa3'}>Назад</Button>
        <Button
          className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 dark:text-neutral-200 ${isPH ? 'bg-[#4f46e5] hover:bg-[#4f46e5]/95':''}`}
          type="submit"
        >Продожить
        </Button>
      </div>
    </form>
  );
};

export default VisaPage4Item;