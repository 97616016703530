import React, {Fragment, useState} from 'react';
import {Dialog, Transition} from "@headlessui/react";
import visaFotoExample from "../../../images/VisaPage/visaFotoExample.jpg"
import Checkbox from "../../../shared/Checkbox/Checkbox";

type PropsType = {
  initialState: boolean
}

const FotoUploadModal = ({initialState}:PropsType) => {
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(initialState);

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  return (
    <>
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed h-screen py-6 px-2 mx-4 top-4 z-50 overflow-y-scroll sm:inset-x-0 sm:p-6"
          onClose={closeModalAmenities}
        >
          <div className="text-center">

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block h-full w-full max-w-4xl">
                <div
                  className="inline-flex p-6 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div
                    className="relative flex-shrink-0 px-6 py-4 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-800 dark:text-neutral-50"
                      id="headlessui-dialog-title-70"
                    >
                      Требования к фотографии на визу
                    </h3>

                  </div>
                  <div className='flex flex-col gap-5 align-middle mt-8 sm:flex-row'>
                    <div>
                      <img className='' src={visaFotoExample} alt={'Foto as example'}/>
                    </div>
                    <div>
                      <p>
                        Нужно загрузить ваше цветное фото, оно может быть как сделано на цифровую камеру, так и
                        отсканировано с
                        бумажного носителя. Требования к фотографии на визу в Сингапур:
                        <li>фон должен быть белым;</li>
                        <li>фото должно быть свежим — не более трёх месяцев до загрузки;</li>
                        <li>не должно быть головного убора и деталей, закрывающих лицо;</li>
                        <li>фотография должна быть четкой, как на паспорт, фото плохого качества повлекут за собой отказ в
                          выдаче
                          визы.
                        </li>
                      </p>
                      <p> Имейте в виду, что фотография не вклеивается и не печатается непосредственно на сингапурской
                        визе, она
                        хранится в базе данных Иммиграционного Ведомства Сингапура, и офицер сравнивает вас с
                        фотоизображением при
                        пересечении границы.
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-end align-middle mt-10 mb-5 mr-8">
                    <Checkbox onChange={closeModalAmenities} className='mr-4' name="accept rules"/>
                    <div>Я ознакомился с данной информацией</div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default FotoUploadModal;