import {Entries} from '../containers/VisaPage/Page/VisaPage';
import {YesNoToBool} from './YesNoToBool';

export const serviceStringCreator = (isExpress: string, withHotel: string, entries: Entries) => {
    let entriesStr = () => {
        switch (entries){
            case Entries.one:
                return ''
            case Entries.two:
                return ' двойной въезд'
            case Entries.multi:
                return ' многократный въезд'
            default:
                return ''
        }
    }
    let sumCalc = () => {
        return (60 + (YesNoToBool(isExpress) ? 40 : 0) + (YesNoToBool(withHotel) ? 0 : 20) + (entries || 0) * 20) || 0
    }
    return (YesNoToBool(isExpress) ? 'Срочная виза ' : 'Виза ') + (YesNoToBool(withHotel) ? 'с отелем' : 'без отеля') + entriesStr() + '-' + sumCalc()
}