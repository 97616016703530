import React, {FC, useState} from 'react';
import CommonLayout from '../CommonLayout';
import Checkbox from '../../../shared/Checkbox/Checkbox';
import Button from '../../../shared/Button/Button';
import {sendFullDataTC, updateApplicationField} from '../../../store/visaPageReducer';
import {NavLink, useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../utils/customHooks';
import Label from '../../../components/Label/Label';
import Textarea from '../../../shared/Textarea/Textarea';
import AdditionServiceModal from './AdditionServiceModal';

export interface PageAddListing1Props {
}

const VisaPage8: FC<PageAddListing1Props> = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const {commentData, fullPrice, uid} = useAppSelector(state => state.visa_page)
    const [isChecked1, setIsChecked1] = useState<boolean>(false)
    const [isChecked2, setIsChecked2] = useState<boolean>(false)
    const onClickHandler = async () => {
        await dispatch(sendFullDataTC())
        navigate('/visa-complete')
    }
    const isPH = uid?.split('-')[0] === 'PH';


    return (
        <CommonLayout
            index="01"
        >
            <>
                <div className={'flex flex-col gap-5'}>
                    <div className={'col-span-2'}>
                        <Label className="text-lg font-semibold">Примечание к заявке:</Label>
                        <Textarea
                            style={{resize:'none'}}
                            className="mt-2"
                            name={'commentData'}
                            placeholder={'Ваше сообщение'}
                            value={commentData}
                            onChange={(e) => {
                                dispatch(updateApplicationField({commentData:e.currentTarget.value}))
                            }}
                        />
                    </div>
                    <label className="text-lg font-semibold text-neutral-700 dark:text-neutral-300" htmlFor="">
                        Проставьте галочки в полях
                    </label>
                    <div className="flex flex-col justify-center align-middle">
                        <div className="flex justify-items-start align-middle mt-4">
                            <Checkbox checked={isChecked1} className="mr-4" name="accept rules"
                                      onChange={() => setIsChecked1((pred) => !pred)}/>
                            <div>Я принимаю условия <NavLink to={'/terms'} target={'_blank'} className={'font-semibold italic'}>пользовательского соглашения</NavLink> и <NavLink to={'/confidentiality'} target={'_blank'} className={'font-semibold italic'}>политику конфиденциальности</NavLink> данного сайта. *</div>
                        </div>
                        <div className="flex justify-items-start align-middle mt-4">
                            <Checkbox checked={isChecked2} className="mr-4" name="final design"
                                      onChange={() => setIsChecked2((pred) => !pred)}/>
                            <div>
                                Окончательное решение о выдаче, сроке действия и кратности визы принимает Миграционное
                                Ведомство
                                Сингапура.
                                При отказе в визе оплаченная сумма не возвращается, т.к. при подаче на визу Миграционное
                                Ведомство берет с
                                нас полную стоимость визы. *
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 md:flex-row justify-between space-x-5 lg:mt-8">
                            <div className={'flex flex-col md:flex-row items-center md:ml-5 font-semibold text-2xl'}>
                                <span className={'mr-2 md:max-w-[200px] lg:max-w-max'}>Окончательная стоимость:</span>
                                <span className={'shrink-0 self-center'}>{fullPrice} USD</span>
                            </div>
                        <div className={'flex justify-center gap-4'}>
                            <Button
                                className={`ttnc-ButtonSecondary font-medium border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800`}
                                href={'/visa7'}>Назад</Button>
                            <Button
                                className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  dark:text-neutral-200 ${isPH ? '!bg-[#4f46e5]':''}`}
                                type="button"
                                disabled={!isChecked1 || !isChecked2}
                                onClick={onClickHandler}
                            >
                                Отправить
                            </Button>
                        </div>
                    </div>
                </div>
                <AdditionServiceModal/>
            </>
        </CommonLayout>
    );
};

export default VisaPage8;
