import React from 'react';
import {useNavigate} from 'react-router-dom';
import completeIcon from '../../images/complete_icon.svg';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';

const PagePaymentSuccess = () => {
    const navigate = useNavigate()

    const renderContent = () => {
        return (
            <div
                className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                <div className="flex justify-center ">
                    <h3 className="sm:block w-min md:w-max text-3xl lg:text-4xl text-center">
                        Платеж отправлен
                    </h3>
                    <img src={completeIcon} alt={'complete'} className="ml-3" width={30}/>
                </div>

                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex flex-col space-y-4">
                    <div className="flex flex-col gap-3 text-neutral-6000 dark:text-neutral-300 text-center">
                        <p className="text-2xl lg:text-3xl">Спасибо, что оперативно оплатили услуги.</p>
                        <p className="text-lg">
                            По факту изменения статуса заявки, Вы получите сообщение на email.
                        </p>
                    </div>
                </div>
                <ButtonPrimary className={'self-end mr-6'} onClick={() => navigate('/')}>На главную</ButtonPrimary>
            </div>
        );
    };

    return (
        <div className={`nc-PayPage`} data-nc-id="PayPage">
            <main className="container mt-24 mb-24 lg:mb-32 ">
                <div className="max-w-4xl mx-auto">{renderContent()}</div>
            </main>
        </div>
    );
};

export default PagePaymentSuccess;