import {ChangeEvent, useState} from 'react';
import {useFormik} from 'formik';
import Label from '../../components/Label/Label';
import Textarea from '../../shared/Textarea/Textarea';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';
import {useAppDispatch, useAppSelector} from '../../utils/customHooks';
import {checkoutServiceMessageTC, contactSendMessageTC} from '../../store/appReducer';

type FormikErrorType = {
  name?: string
  tel?: string
  email?: string
}


const ContactForm = () => {
  const dispatch = useAppDispatch()
  const {service} = useAppSelector(state => state.app)
  const [files, setFiles] = useState<Array<File>>([])
  const [isOpened, setIsOpened] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      tel: '',
      text: ''
    },
    validate: (values) => {
      const errors: FormikErrorType = {};
      if (!values.email) {
        errors.email = 'Поле обязательно к заполнению';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = 'Неправильный email адрес';
      }
      if (!values.tel) {
        errors.tel = 'Поле обязательно к заполнению';
      } else if (!/^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/.test(values.tel)) {
        errors.tel = 'Введите корректный номер'
      }
      if (!values.name) {
        errors.name = 'Поле обязательно к заполнению'
      }

      return errors;
    },
    onSubmit: async (data) => {
      await dispatch(checkoutServiceMessageTC({...data, ...service}))
      setIsOpened(true)
      setTimeout(() => {
        setIsOpened(false)
      }, 2000)
      formik.resetForm()
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="grid grid-cols-1 gap-6">
        <label className="block relative">
          <Label>Ваши имя и фамилия</Label>
          <input
            placeholder="Иван Иванов"
            type="text"
            className="mt-2"
            {...formik.getFieldProps('name')}
          />
          {formik.touched.name && formik.errors.name ?
            <div className={'absolute bottom-[-26px] text-red-700 ml-5'}>{formik.errors.name}</div> : null}
        </label>
        <label className="block relative">
          <Label>Телефон</Label>
          <input
            type="tel"
            className="mt-2"
            placeholder="+7 (123) 456-78-90"
            {...formik.getFieldProps('tel')}
          />
          {formik.touched.tel && formik.errors.tel ?
            <div className={'absolute bottom-[-26px] text-red-700 ml-5'}>{formik.errors.tel}</div> : null}
        </label>
        <label className="block relative">
          <Label>Email</Label>
          <input
            type="email"
            className="mt-2"
            placeholder="example@example.com"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ?
            <div className={'absolute bottom-[-26px] text-red-700 ml-5'}>{formik.errors.email}</div> : null}
        </label>
        <label className="block relative">
          <Label>Сообщение</Label>
          <Textarea className="mt-2" rows={6} {...formik.getFieldProps('text')}/>
          {formik.touched.text && formik.errors.text ?
            <div className={'text-red-700 ml-5 mt-3'}>{formik.errors.text}</div> : null}
        </label>
        <div>
          <ButtonPrimary type="submit">Отправить запрос</ButtonPrimary>
        </div>
      </form>
      {isOpened && <div className={'fixed z-50 inset-0 bg-neutral-800/70 flex items-center justify-center'}>
          <h3 className={'text-3xl text-center'}>Ваше сообщение отправлено</h3>
      </div>}
    </div>
  );
};

export default ContactForm;