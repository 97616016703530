import React, {FC, useEffect, useState} from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {useAppDispatch, useAppSelector} from '../../utils/customHooks';
import {getVisaTC} from '../../store/visaCheckReducer';
import trc20 from './../../images/TRC20.png'
import erc20 from './../../images/ERC20.png'
import content_copy from './../../images/content-copy.svg'
import close from './../../images/close.svg'
import {PaymentInput} from './PaymentInput';
import {accountingSuccessTC, clearState, rateObjType} from '../../store/visaPageReducer';
import {setAppStatusAC} from '../../store/appReducer';


export interface PageSubscriptionProps {
  className?: string;
}

export interface PricingItem {
  name: string;
  currency: string
  pricing: number;
  desc: string;
  prop: {
    img?: string
    url?: string
    address?: string
    descr: string
    billing_name?: string
  }
}

type getRatesResponse = {
  base: string
  timestamp: string
  rates: {
    RUB: number
    SGD: number
  }
}


const PagePayment: FC<PageSubscriptionProps> = ({className = ''}) => {
  const {fullPrice, email, visa_status, numberOfApplicants, ruCardPreference} = useAppSelector(state => state.visa_page)
  const {uid} = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [rates, setRates] = useState<rateObjType>({
    usdRubRate: 0,
    usdSgdRate: 0
  })
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [selectData, setSelectData] = useState<PricingItem>({
    currency: '',
    desc: '',
    name: '',
    pricing: 0,
    prop: {
      address: '',
      descr: ''
    }
  })
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [imagesArr, setImagesArr] = useState<Array<string>>([])

  const successHandler = async () => {
    const getPaymentData = () => {
      let name = selectData.name + ' payment'
      let paymentType = selectData.name
      if (selectData.name === 'Перевод на карту Российского банка') {
        name = !ruCardPreference || ruCardPreference === 'Ира' ? 'Перевод на карту Сбер Ира payment' : 'Перевод на карту Т-банк Леша payment'
        paymentType = !ruCardPreference || ruCardPreference === 'Ира' ? 'Карта Сбер Ира' : 'Карта Т-банк Леша'
      }
      return {
        uid,
        email,
        name,
        rates,
        currency: selectData.currency,
        value: selectData.pricing,
        fullPrice: selectData.currency + ' ' + selectData.pricing,
        imagesArr,
        paymentType,
        numberOfApplicants
      }
    }

    let res = await dispatch(accountingSuccessTC(getPaymentData()))
    if (res.payload) {
      navigate('/payment_success')
    }
  }
  const paymentHandler = async () => {
    if (selectData.name === 'Stripe') {
      dispatch(setAppStatusAC({status: 'loading'}))
      let res = await axios.post('https://sentosastripeserver-production.up.railway.app/payment', {
        uid,
        fullPrice: selectData.pricing,
        description: `Payment for visa to Singapore. Application № ${uid} , email: ${email}`,
        email: email
      })
      window.location.replace(res.data.url)
    } else {
      selectData.prop.url && window.open(selectData.prop.url);
    }
  }

  useEffect(() => {
    axios.get<getRatesResponse>('https://openexchangerates.org/api/latest.json?app_id=eefaec644baf4069b485ed42d36857f7&base=USD&symbols=RUB,SGD,THB').then(res => {
      if (res.data.timestamp) {
        setRates({
          usdRubRate: res.data.rates.RUB + 10,
          usdSgdRate: res.data.rates.SGD
        })
      }
    })
    uid && dispatch(getVisaTC(uid))
    return () => {
      dispatch(clearState())
    }
  }, [])

  const pricings: PricingItem[] = [
    {
      name: 'Stripe',
      currency: 'SGD',
      pricing: Math.ceil(fullPrice * rates.usdSgdRate * 1.035),
      desc: `Оплата с использованием платежной системы Stripe.`,
      prop: {
        url: 'http://sentosastripeserver-production.up.railway.app/payment',
        descr: `Вам необходимо перейти по ссылке и оплатить услуги, затем загрузить чек оплаты.`
      }
    },
    {
      name: 'Revolut',
      currency: 'SGD',
      pricing: Math.ceil(fullPrice * rates.usdSgdRate),
      desc: `Оплата с использованием платежной системы Revolut.`,
      prop: {
        url: 'https://revolut.me/dmitry49zd',
        descr: `Вам необходимо перейти по ссылке и оплатить услуги. В примечании указать e-mail заявки. Затем загрузить скриншот оплаты.`
      }
    },
    {
      name: 'Перевод на карту Российского банка',
      currency: 'RUB',
      pricing: Math.ceil(fullPrice * rates.usdRubRate),
      desc: `Перевод на карту Сбербанка`,
      prop: {
        address: '+79131732763',
        billing_name: 'Ирина Игоревна Ч.',
        descr: `Вам необходимо выполнить перевод по номеру телефона на карту Сбербанка.
                    Обменный курс рассчитывается исходя из курса ЦБ РФ + 10 руб за 1 USD. Затем загрузить скриншот оплаты.`
      }
    },
    {
      name: 'Перевод на карту Российского банка',
      currency: 'RUB',
      pricing: Math.ceil(fullPrice * rates.usdRubRate),
      desc: `Перевод на карту Т-Банка`,
      prop: {
        address: '+79043817630',
        billing_name: 'Алексей Викторович Б.',
        descr: `Вам необходимо выполнить перевод по номеру телефона на карту Т-Банка.
                    Обменный курс рассчитывается исходя из курса ЦБ РФ + 10 руб за 1 USD. Затем загрузить скриншот оплаты.`
      }
    },
    {
      name: 'USDT(TRC20)',
      currency: '$',
      pricing: Math.ceil(fullPrice * 1.03),
      desc: `Перевод USDT в сети TRON (TRC20)`,
      prop: {
        img: trc20,
        address: 'TCRK4mUppuzuyW5MiijkY5njQE8CHwmKeS',
        descr: `Вам необходимо выполнить перевод USDT в сети TRON(TRC20) по указанным реквизитам и загрузить скриншот оплаты.`
      }
    },
    {
      name: 'USDT(ERC20)',
      currency: '$',
      pricing: Math.ceil(fullPrice * 1.03),
      desc: `Перевод USDT в сети Ethereum (ERC20)`,
      prop: {
        img: erc20,
        address: '0x303e770fc8c2C8186C82a5F814dae9f15bA4EFbb',
        descr: `Вам необходимо выполнить перевод USDT в сети Ethereum (ERC20) по указанным реквизитам и загрузить скриншот оплаты.`
      }
    },
  ];

  const pricingFinal = pricings.filter((el) => {
    if(el.name === 'Перевод на карту Российского банка') {
      switch (ruCardPreference) {
        case 'Ира':
          return el.prop.address === '+79131732763'
        case 'Леша':
          return el.prop.address === '+79043817630'
        default:
          return el.prop.address === '+79131732763'
      }
    }
    return true
  })

  const renderPricingItem = (pricing: PricingItem, index: number) => {
    return (
      <div
        key={index}
        className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden border-neutral-100 dark:border-neutral-700
                `}
      >
        <div className="mb-8">
          <h3
            className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
            {pricing.name}
          </h3>
          <h2 className="text-5xl leading-none flex items-center text-neutral-900 dark:text-neutral-300">
            <span>{pricing.currency} {pricing.pricing}</span>
          </h2>
          <p className="text-base text-neutral-500 dark:text-neutral-400 mt-3">
            {pricing.desc}
          </p>
        </div>
        <div className="flex flex-col mt-auto">
          <ButtonSecondary onClick={() => {
            setSelectData(pricing)
            setIsSelected(true)
          }}>
                        <span className="font-medium">
                            Выбрать
                        </span>
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  if (visa_status !== 'ожидает оплаты') {
    return <></>
  }

  return (
    <div
      className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
      data-nc-id="PagePayment"
    >
      <header className="text-center max-w-2xl mx-auto my-10">
        <h2
          className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          <span className="mr-4 text-3xl md:text-4xl leading-none">💎</span>
          Оплата услуг
        </h2>
        {!isSelected
          ? <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                      Выберите подходящий способ оплаты.
                      </span>
          : <span>
                        Оплатите услуги выбранным методом и загружите чек оплаты.
                    </span>
        }
      </header>
      {isSelected
        ? <section className="flex justify-center text-neutral-600 text-sm md:text-base overflow-hidden">
          <div
            className={`h-full self-center max-w-screen-md relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden border-neutral-100 dark:border-neutral-700
                `}
          >
            <div className="mb-8 text-center">
              <h2
                className="text-4xl leading-none flex flex-col gap-2 items-center text-neutral-900 dark:text-neutral-300">
                Выбранный способ оплаты: <span
                className={'text-neutral-700 dark:text-neutral-300 font-medium'}>{selectData.name}</span>
              </h2>
              <p className="text-base  text-neutral-500 dark:text-neutral-400 my-3">
                {selectData.prop.descr}
              </p>
              <div>
                <p className="block mt-2 text-xl text-semibold text-neutral-700 dark:text-neutral-200">
                  Реквизиты для оплаты:
                </p>
                <div className={'flex flex-col items-center'}>
                  {selectData.prop.img && <div className={'w-2/5 rounded-xl overflow-hidden mt-3'}>
                      <img src={selectData.prop.img} alt="crypto address"/>
                  </div>}
                  <p className={'text-bold font-medium relative text-neutral-700 dark:text-neutral-300 mt-4'}>
                    {selectData.prop.address}
                    <img src={content_copy} alt="copy"
                         className={'w-[20px] cursor-pointer absolute right-[-30px] top-[1px]'}
                         onClick={() => {
                           selectData.prop.address && navigator.clipboard.writeText(selectData.prop.address)
                         }}/>
                  </p>
                  {selectData.prop?.billing_name && <p className={'mt-1 font-medium text-neutral-700 dark:text-neutral-300'}>
                   <span className={'font-normal'}>Получатель:</span> {selectData.prop.billing_name}
                  </p>}
                  {selectData.prop.url && <ButtonSecondary onClick={paymentHandler}>
                      Перейти к оплате
                  </ButtonSecondary>}
                </div>
              </div>
              <h3
                className="block text-2xl uppercase tracking-widest text-neutral-700 dark:text-neutral-300 my-4 font-medium">
                <span>К оплате: {selectData.currency} {selectData.pricing}</span>
              </h3>
            </div>
            <div className="flex justify-end gap-3 mt-auto">
              <ButtonSecondary onClick={() => setIsSelected(false)}>
                <span className="font-medium">Другой способ оплаты</span>
              </ButtonSecondary>
              <ButtonPrimary onClick={() => {
                setIsOpen(true)
              }}>
                <span className="font-medium">Загрузить скриншот</span>
              </ButtonPrimary>
            </div>
          </div>
        </section>
        : <section className="text-neutral-600 text-sm md:text-base overflow-hidden">
          <div className="grid lg:grid-cols-3 gap-5 xl:gap-8 ">
            {pricingFinal.map(renderPricingItem)}
          </div>
        </section>}
      {isOpen && <div className={'flex justify-center items-center fixed z-40 inset-0 bg-neutral-700/70'}>
          <div
              className={'relative flex flex-col gap-3 max-w-2xl bg-white/100 dark:bg-neutral-900/100 rounded-2xl p-6 text-center'}>
              <h2 className={'text-2xl font-semibold text-neutral-900 dark:text-neutral-100'}>Загрузите
                  скриншот</h2>
              <div
                  className={'absolute top-[-40px] right-[-40px] cursor-pointer rounded-full border-2 border-[#9e9e9e]'}
                  onClick={() => {
                    setIsOpen(false)
                  }}>
                  <img src={close} alt={'close'}/>
              </div>
              <PaymentInput images={imagesArr} setImages={setImagesArr}/>
              <ButtonPrimary
                  onClick={successHandler}
              >
                  Отправить
              </ButtonPrimary>
          </div>
      </div>}
    </div>
  );
};

export default PagePayment;
