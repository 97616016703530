import React, {FC, useEffect, useState} from 'react';
import Input from 'shared/Input/Input';
import Select from 'shared/Select/Select';
import CommonLayout from '../CommonLayout';
import FormItem from '../FormItem';
import {useFormik} from 'formik'
import {useAppDispatch, useAppSelector} from '../../../utils/customHooks';
import {setVisaPageDataTC} from '../../../store/visaPageReducer';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Button from '../../../shared/Button/Button';
import {YesNoToBool} from '../../../utils/YesNoToBool';
import {serviceStringCreator} from '../../../utils/serviceStringCreator';

type FormikErrorType = {
    service?: string | undefined
    email?: string | undefined
    tel?: string | undefined
    visitPurpose?: string | undefined
    numberOfApplicants?: string | undefined
    isExpress?: string
    withHotel?: string
    entries?: string
}
export type VisaPageFormikDataType = {
    service: string
    email: string
    tel: string
    visitPurpose: string
    numberOfApplicants: string
    isExpress: string
    withHotel: string
    entries: number

    fullPrice?: number
    fullName?: string
    male?: string
    citizenship?: string
    familyStatus?: string
    mainApplicantIs?: string
}

export enum Entries {
    one,
    two,
    multi
}

const VisaPage: FC = () => {
    const dispatch = useAppDispatch()
    const {
        service,
        numberOfApplicants,
        tel,
        visitPurpose,
        email,
        fullPrice,
        serviceData: {isExpress, entries, withHotel}
    } = useAppSelector(state => state.visa_page)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const [price, setPrice] = useState(fullPrice)
    const prefix = searchParams.get('prefix') || ''
    const formik = useFormik({
        validate: (values) => {
            const errors: FormikErrorType = {}
            if (!values.isExpress) {
                errors.isExpress = 'Укажите срок оформления'
            }
            if (!values.withHotel) {
                errors.withHotel = 'Укажите как бронируете отель'
            }
            if (values.entries == -1) {
                errors.entries = 'Укажите тип визы'
            }
            if (!values.email) {
                errors.email = 'Укажите Ваш e-mail'
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = 'Неправильный email адрес';
            }
            if (!values.tel) {
                errors.tel = 'Укажите Ваш телефон'
            } else if (!/^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/.test(values.tel)) {
                errors.tel = 'Введите корректный номер'
            }
            if (!values.visitPurpose) {
                errors.visitPurpose = 'Укажите цель визита'
            }
            if (!values.numberOfApplicants) {
                errors.numberOfApplicants = 'Укажите кол-во заявителей'
            }
            return errors
        },
        initialValues: {
            service,
            fullPrice,
            isExpress,
            withHotel,
            entries,
            email,
            tel,
            visitPurpose,
            numberOfApplicants,
        },
        onSubmit: async (values: VisaPageFormikDataType) => {
            let {entries, withHotel, isExpress, ...rest} = values
            await dispatch(setVisaPageDataTC({
                ...rest,
                prefix,
                serviceData:{
                    entries, withHotel, isExpress
                }
            }))
            navigate('/visa1')
        }
    })
    useEffect(()=> {
        //generate service string
        let str = serviceStringCreator(formik.values.isExpress, formik.values.withHotel, Number(formik.values.entries))
        formik.setFieldValue('service', str)

        //calc price on form page
        let finalPrice = (60 + (YesNoToBool(formik.values.isExpress) ? 40 : 0) + (YesNoToBool(formik.values.withHotel) ? 0 : 20) + ((formik.values.entries || 0) * 20)) * +formik.values.numberOfApplicants
        setPrice(finalPrice)

    }, [formik.values.isExpress, formik.values.withHotel, formik.values.entries, formik.values.numberOfApplicants])
    return (
        <CommonLayout
            index="01"
        >
            <form onSubmit={formik.handleSubmit}>
                <div className={'listingSection__wrap max-w-full mt-6 mb-4'}>
                    {/* FORM */}
                    <div className={'mt-px'}>
                        {/* ITEM */}
                        <div className={'grid md:grid-cols-1 md:gap-2'}>
                            <FormItem
                                label="Срок оформления"
                            >
                                <Select
                                    className={formik.errors.isExpress && formik.touched.isExpress ? 'border-rose-600' : ''}
                                    {...formik.getFieldProps('isExpress')}
                                >
                                    <option value={''}>-Выберите срок оформления-</option>
                                    <option value={'Да'}>
                                        Срочная виза (1-2 рабочих дня)
                                    </option>
                                    <option value={'Нет'}>
                                        Стандартная виза (3-4 рабочих дня)
                                    </option>
                                </Select>
                                {formik.errors.isExpress && formik.touched.isExpress ?
                                    <div className="text-rose-600">{formik.errors.isExpress}</div> : null}
                                {formik.values.isExpress === 'Да' && <p className={'italic px-2 pt-2 text-sm leading-tight'}>Срочное оформление означает, что ваша заявка будет подана в систему ICA вне очереди, включая выходные и праздничные дни.</p>}
                            </FormItem>
                          {/*  <FormItem
                                label="Бронирование отеля"
                            >
                                <Select
                                    className={formik.errors.withHotel && formik.touched.withHotel ? 'border-rose-600' : ''}
                                    {...formik.getFieldProps('withHotel')}
                                >
                                    <option value={''}>
                                        -Выберите как бронируете отель-
                                    </option>
                                    <option value={'Да'}>
                                        Через нашу компанию
                                    </option>
                                    <option value={'Нет'}>
                                        Самостоятельно
                                    </option>
                                </Select>
                                {formik.errors.withHotel && formik.touched.withHotel ?
                                    <div className="text-rose-600">{formik.errors.withHotel}</div> : null}
                            </FormItem>*/}
                        </div>
                        <FormItem
                            label="Тип визы"
                        >
                            <Select className={formik.errors.entries && formik.touched.entries ? 'border-rose-600' : ''}
                                    {...formik.getFieldProps('entries')}
                            >
                                <option value={-1}>
                                    -Выберите тип визы-
                                </option>
                                <option value={Entries.one}>
                                    Однократный въезд
                                </option>
                                <option value={Entries.two}>
                                    Двухкратный въезд
                                </option>
                                <option value={Entries.multi}>
                                    Многократный въезд
                                </option>
                            </Select>
                            {formik.errors.entries && formik.touched.entries ?
                                <div className="text-rose-600">{formik.errors.entries}</div> : null}
                        </FormItem>
                        <FormItem
                            label="Ваша электронная почта"
                        >
                            <Input
                                className={formik.errors.email && formik.touched.email ? 'border-rose-600' : '' }
                                placeholder="Ваш е-mail"
                                disabled={!!email}
                                {...formik.getFieldProps('email')}
                            />
                            {formik.errors.email && formik.touched.email ?
                                <div className="text-rose-600">{formik.errors.email}</div> : null}
                        </FormItem>
                        <FormItem
                            label="Ваш телефон"
                        >
                            <Input
                                placeholder="Ваш номер телефона"
                                className={formik.errors.tel && formik.touched.tel ? 'border-rose-600' : ''}
                                {...formik.getFieldProps('tel')}
                            />
                            {formik.errors.tel && formik.touched.tel ?
                                <div className="text-rose-600">{formik.errors.tel}</div> : null}
                        </FormItem>
                        <div className="flex flex-col justify-between align-top gap-2 sm:flex-row">
                            <div className="grow">
                                <label
                                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 ">Выберите
                                    цель
                                    визита</label>
                                <Select
                                    className={formik.errors.visitPurpose && formik.touched.visitPurpose ? 'mt-4 border-rose-600' : 'mt-4'}
                                    {...formik.getFieldProps('visitPurpose')}
                                >
                                    <option value="">Выберите цель визита</option>
                                    <option value="Туризм">Туризм</option>
                                    <option value="Транзит">Транзит</option>
                                    <option value="Круиз">Круиз</option>
                                </Select>
                                {formik.errors.visitPurpose && formik.touched.visitPurpose ?
                                    <div className="text-rose-600">{formik.errors.visitPurpose}</div> : null}
                            </div>
                            <div className="grow">
                                <label
                                    className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 ">Выберите
                                    кол-во
                                    заявителей</label>
                                <Select
                                    className={formik.errors.numberOfApplicants && formik.touched.numberOfApplicants ? 'mt-4 border-rose-600' : 'mt-4'}
                                    {...formik.getFieldProps('numberOfApplicants')}
                                >
                                    <option value="">Выберите кол-во заявителей</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </Select>
                                {formik.errors.numberOfApplicants && formik.touched.numberOfApplicants ?
                                    <div className="text-rose-600">{formik.errors.numberOfApplicants}</div> : null}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 sm:flex-row justify-between space-x-5 mt-8">
                        <div className={'flex flex-col sm:flex-row items-center ml-5'}>
                            <p className={'font-semibold text-lg'}>Стоимость услуги: {price} USD</p>
                        </div>
                        <Button
                            className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 $\{className} dark:text-neutral-200 ${prefix === 'PH' ? 'bg-[#4f46e5] hover:bg-[#4f46e5]/95' : ''}`}
                            type="submit"
                        >Продолжить
                        </Button>
                    </div>
                </div>

            </form>
        </CommonLayout>
    );
};

export default VisaPage;
