import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonPrimaryProps extends ButtonProps {}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  className = "",
  ...args
}) => {
  return (
    <Button type={args.type}
      className={`ttnc-ButtonPrimary disabled:bg-opacity-40 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${className} dark:text-neutral-200`}
      {...args}
    />
  );
};

export default ButtonPrimary;
