import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import Page404 from "containers/Page404/Page404";
import VisaPage2 from "../containers/VisaPage/page2/VisaPage2";
import VisaPage3 from "../containers/VisaPage/Page3/VisaPage3";
import VisaPage1 from "../containers/VisaPage/Page1/VisaPage1";
import VisaPage from "../containers/VisaPage/Page/VisaPage";
import VisaCheckResultPage from '../components/VisaCheckPage/VisaCheckResultPage';
import TourPage from "containers/Tours/Tours";
import EditPhotoPage from '../components/VisaCheckPage/EditPhotoPage/EditPhotoPage';
import EditSuccessPage from '../components/VisaCheckPage/EditPhotoPage/EditSuccessPage';
import PagePayment from '../containers/PagePayment/PagePayment';
import PagePaymentSuccess from '../containers/PagePayment/PagePaymentSuccess';
import PageUploadReceipt from '../containers/PagePayment/PageUploadReceipt/PageUploadReceipt';
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import VisaPage4 from "../containers/VisaPage/Page4/VisaPage4";
import VisaPage5 from "../containers/VisaPage/Page5/VisaPage5";
import VisaPage6 from "../containers/VisaPage/Page6/VisaPage6";
import VisaPage7 from "../containers/VisaPage/Page7/VisaPage7";
import VisaPage8 from "../containers/VisaPage/Page8/VisaPage8";
import VisaComplete from "../containers/VisaPage/VisaComplete/VisaComplete";
import VisaCheckPage from "../components/VisaCheckPage/VisaCheckPage";

export const pages: Page[] = [
  { path: "/visa", component: VisaPage },
  { path: "/visa1", component: VisaPage1 },
  { path: "/visa2", component: VisaPage2 },
  { path: "/visa3", component: VisaPage3 },
  { path: "/visa4", component: VisaPage4 },
  { path: "/visa5", component: VisaPage5 },
  { path: "/visa6", component: VisaPage6 },
  { path: "/visa7", component: VisaPage7 },
  { path: "/visa8", component: VisaPage8 },
  { path: "/visa-complete", component: VisaComplete },
  { path: "/visa-check", component: VisaCheckPage },
  { path: "/check_result/:uid", component: VisaCheckResultPage },
  { path: "/edit_photo/:uid", component: EditPhotoPage },
  { path: "/edit_success", component: EditSuccessPage },
  { path: "/tour/:id", component: TourPage },
  { path: "/payment/:uid", component: PagePayment },
  { path: "/payment_success", component: PagePaymentSuccess },
  { path: "/upload_receipt/:uid", component: PageUploadReceipt },
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <LoadingScreen/>
      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route path={'*'} element={<Page404/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;
