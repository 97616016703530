import React, {Fragment, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import Button from '../../../shared/Button/Button';
import {useAppDispatch, useAppSelector} from '../../../utils/customHooks';
import ButtonClose from '../../../shared/ButtonClose/ButtonClose';
import {additionServiceTC} from '../../../store/visaPageReducer';
import moment from 'moment/moment';
import Checkbox from '../../../shared/Checkbox/Checkbox';


const AdditionServiceModal = () => {
    const dispatch = useAppDispatch();
    const {additionalService, numberOfApplicants, applicantsData} = useAppSelector(state => state.visa_page)
    const insuranceArr = applicantsData.map(el => ({
        inDate: el.flightInDate, outDate: el.flightOutDate, fullName: el.fullName, additional: el.additionalServiceData
    }))
    const [isOpen, setIsOpen] = useState<boolean>(true)

    const calcPrice = () => {
        const days = moment(insuranceArr[0].outDate).diff(insuranceArr[0].inDate, 'days') + 1
        return days < 6
            ? 15
            : 15 + (days - 5) * 2
    }
    const checkBoxHandler = (newValue: boolean, applicantIndex: number) => {
        let value = newValue ? additionalService?.insurance + 1 : additionalService?.insurance - 1
        dispatch(additionServiceTC({serviceName: 'insurance', value, isChecked: newValue, applicantIndex}))
    }

    return (
        <div className={'bg-black/30'}>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-50 inset-0 flex justify-center items-start"
                    onClose={() => setIsOpen(false)}
                >
                    <div className="fixed inset-0 bg-black/30 dark:bg-black/50" aria-hidden="true"/>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="relative h-[95vh] overflow-y-scroll rounded-l-2xl max-w-3xl m-3 sm:m-20">
                            <div className={'absolute top-[8px] right-[8px] z-[60]'}>
                                <ButtonClose
                                    className={'bg-black/5 dark:bg-neutral-900 dark:border dark:border-neutral-700'}
                                    onClick={() => setIsOpen(false)}/>
                            </div>
                            <div
                                className="inline-flex px-8 py-8 flex-col max-md:h-[95vh] w-full text-left align-middle transition-all transform  bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl overflow-hidden md:overflow-auto">
                                <div
                                    className="relative flex-shrink-0 pb-8 text-center">
                                    <h3
                                        className="text-3xl font-medium leading-6 text-gray-800 dark:text-neutral-50"
                                        id="headlessui-dialog-title-70"
                                    >
                                        Дополнительные услуги:
                                    </h3>

                                </div>
                                <div className="flex flex-col gap-5 md:gap-10">
                                    <div className={'grid auto-rows-min grid-cols-1 space-y-4 md:gap-4 md:grid-cols-3 md:grid-rows-1'}>
                                        <div className={'text-center md:col-span-2 md:text-start'}>
                                            <h2 className="text-2xl font-medium leading-6 mb-2 text-gray-800 dark:text-neutral-50">Иммиграционная карта Сингапура</h2>
                                            <div>
                                                <p>Не ранее чем за три для до прибытия в Сингапур вы должны заполнить иммиграционную карту Сингапура.
                                                    Вы можете заказать услугу заполнения иммиграционной карты у нас и мы вышлем ее вам за два для до вылета.</p>
                                                <p><span className={'font-bold text-xl'}>Стоимость услуги:</span> <b>10
                                                    USD</b> за человека</p>
                                            </div>
                                        </div>
                                        {additionalService && additionalService?.arrivalCard !== 0
                                            ? <Button
                                                className="ttnc-ButtonSecondary font-medium border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 w-[180px] self-center md:self-end justify-self-center"
                                                type="button"
                                                onClick={
                                                    () => dispatch(additionServiceTC({
                                                        serviceName: 'arrivalCard',
                                                        value: 0
                                                    }))
                                                }
                                            >
                                                Удалить услугу
                                            </Button>
                                            : <Button
                                                className="ttnc-ButtonPrimary w-[180px] justify-self-center self-center md:self-end disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 dark:text-neutral-200"
                                                type="button"
                                                onClick={
                                                    () => dispatch(additionServiceTC({
                                                        serviceName: 'arrivalCard',
                                                        value: +numberOfApplicants
                                                    }))
                                                }
                                            >
                                                Добавить услугу
                                            </Button>
                                        }
                                    </div>
                                    <div className={'grid grid-cols-1 md:gap-4 md:grid-cols-3 md:grid-rows-1 h-min'}>
                                        <div className={'text-center md:col-span-2 md:text-start'}>
                                            <h2 className="text-2xl font-medium leading-6 mb-2 text-gray-800 dark:text-neutral-50">
                                                Медицинская страховка:
                                            </h2>
                                            <div>
                                                <p>
                                                    Мы предлагаем Вам медицинскую страховку
                                                    <span
                                                        className={'font-medium'}> на время прибывания в Сингапуре</span>.
                                                    Страховака включает покрытие случаев заболевания COVID-19.
                                                </p>
                                                <p><span className={'font-bold text-xl'}>Стоимость услуги:</span> <b>
                                                    {calcPrice()} USD</b> за заявителя</p>
                                            </div>
                                        </div>
                                        <div className={'flex flex-col mt-4 h-min md:self-center justify-self-center gap-4'}>
                                            {additionalService && additionalService?.insurance !== 0
                                                ? <>
                                                    <p className={'text-xl font-bold'}>Для заявителей:</p>
                                                    {insuranceArr.map((el, index) => {
                                                        return (
                                                            <div key={el.fullName}>
                                                                <Checkbox
                                                                    key={el.fullName}
                                                                    checked={el.additional.serviceNames.includes('insurance')}
                                                                    name={el.fullName} label={el.fullName}
                                                                    className={'capitalize font-bold text-md self-center'}
                                                                    onChange={(value) => checkBoxHandler(value, index)}/>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </>
                                                : <Button
                                                    className="ttnc-ButtonPrimary w-[180px] disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 dark:text-neutral-200"
                                                    type="button"
                                                    onClick={
                                                        () => dispatch(additionServiceTC({
                                                            serviceName: 'insurance',
                                                            value: +numberOfApplicants
                                                        }))
                                                    }
                                                >
                                                    Добавить услугу
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    className="ttnc-ButtonPrimary justify-self-end self-end disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 dark:text-neutral-200] md:self-center mt-8 "
                                    type="button"
                                    onClick={
                                        () => setIsOpen(false)
                                    }>
                                    Подтвердить
                                </Button>
                            </div>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition>
        </div>
    );
};

export default AdditionServiceModal;