import React, {FC, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../utils/customHooks';
import ButtonPrimary from '../../shared/Button/ButtonPrimary';
import completeIcon from '../../images/complete_icon.svg';
import downloadIcon from '../../images/download.svg';
import {clearState} from '../../store/visaPageReducer';
import {useNavigate, useParams} from 'react-router-dom';
import {getVisaTC} from '../../store/visaCheckReducer';
import ButtonOrange from "../../shared/Button/ButtonOrange";
import {createVoucher} from '../../utils/createHotelVoucher';

export interface VisaCheckResultProps {
    className?: string;
}

const VisaCheckResultPage: FC<VisaCheckResultProps> = ({className = ''}) => {
    const {tel, numberOfApplicants, visa_status, file, additionalService} = useAppSelector(state => state.visa_check)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {uid} = useParams()
    useEffect(() => {
        !tel && uid && dispatch(getVisaTC(uid))
        return () => {
            dispatch(clearState());
        };
    }, []);

    const renderContent = () => {
        return (
            <div
                className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                <div className="flex justify-center">
                    <h3 className="text-3xl lg:text-4xl text-center">
                        Результат проверки статуса Вашей визы
                    </h3>
                    <img src={completeIcon} alt={'complete'} className="ml-3" width={30}/>
                </div>

                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

                {/* ------------------------ */}
                <div className="space-y-6">
                    <h3 className="text-2xl font-semibold">Детали заказа</h3>
                    <div className="flex flex-col space-y-4">
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                            <span className="flex-1">Количество заявителей</span>
                            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {numberOfApplicants}
              </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                            <span className="flex-1">Номер телефона:</span>
                            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {tel}
              </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                            <span className="flex-1">Статус</span>
                            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {visa_status}
              </span>
                        </div>
                    </div>
                </div>
                {!!file?.length &&
                    <div>
                        <p className={'font-bold text-lg mb-6'}>Ссылки для скачивания ваших документов:</p>
                        <div className={'flex gap-10'}>
                            {file.filter(el => el !== '').map((el,i) => {
                                return (<ButtonOrange key={i} onClick={() => getFile(el, 'visa-' + i+1)} className={'.border-solid border-blue-400 bg-transparent text-blue-400 border items-center'}>
                                    Скачать визу <img src={downloadIcon} alt={'download'} className={'w-[18px] ml-2 relative top-[1px] text-primary-400'}/>
                                </ButtonOrange>)
                            })}
                        </div>
                    </div>}
                {!!additionalService && !!additionalService?.arrivalCardData?.length &&
                    <div>
                        <p className={'font-bold text-lg mb-6'}>Ссылки для скачивания ваших Arrival cards:</p>
                        <div className={'flex gap-10'}>
                            {additionalService?.arrivalCardData.filter(el => el !== '').map((el,i) => {
                                return (<ButtonOrange key={i} onClick={() => getFile(el, 'arrival_card-' + i+1)} className={'.border-solid border-blue-400 bg-transparent text-blue-400 border items-center'}>
                                    Скачать Arrival Card <img src={downloadIcon} alt={'download'} className={'w-[18px] ml-2 relative top-[1px] text-primary-400'}/>
                                </ButtonOrange>)
                            })}
                        </div>
                    </div>}
                {!!additionalService && !!additionalService?.insuranceData?.length &&
                    <div>
                        <p className={'font-bold text-lg mb-6'}>Ссылки для скачивания ваших страховок:</p>
                        <div className={'flex gap-10'}>
                            {additionalService?.insuranceData.filter(el => el !== '').map((el,i) => {
                                return (<ButtonOrange key={i} onClick={() => getFile(el, 'insurance-' + i+1)} className={'.border-solid border-blue-400 bg-transparent text-blue-400 border items-center'}>
                                    Скачать страховку <img src={downloadIcon} alt={'download'} className={'w-[18px] ml-2 relative top-[1px] text-primary-400'}/>
                                </ButtonOrange>)
                            })}
                        </div>
                    </div>}
                {!!additionalService && !!additionalService?.hotelData?.length &&
                    <div>
                        <p className={'font-bold text-lg mb-6'}>Ссылки для скачивания ваших отельных ваучеров:</p>
                        <div className={'flex gap-10'}>
                            {additionalService?.hotelData.filter(el => el.hotelName !== '').map((el,i) => {
                                return (<ButtonOrange key={i} onClick={() => createVoucher(el)} className={'.border-solid border-blue-400 bg-transparent text-blue-400 border items-center'}>
                                    Скачать ваучер <img src={downloadIcon} alt={'download'} className={'w-[18px] ml-2 relative top-[1px] text-primary-400'}/>
                                </ButtonOrange>)
                            })}
                        </div>
                    </div>}
                {visa_status === 'плохое фото' &&
                    <div>
                        <p className={'font-bold text-lg mb-6'}>Пожалуйста, нажмите на кнопку ниже и загрузите новое фото:</p>
                        <div className={'flex gap-10'}>
                            <ButtonPrimary onClick={() => {
                                navigate('/edit_photo/' + uid)
                            }}>Загрузить новое фото</ButtonPrimary>
                        </div>
                    </div>
                }
                {visa_status === 'ожидает оплаты' &&
                    <div>
                        <p className={'font-bold text-lg mb-6'}>Пожалуйста, нажмите на кнопку ниже и перейдите к оплате:</p>
                        <div className={'flex gap-10'}>
                            <ButtonPrimary onClick={() => {
                                navigate('/payment/' + uid)
                            }}>Перейти к оплате</ButtonPrimary>
                        </div>
                    </div>
                }
            </div>
        );
    };

    const getFile = (data: string, fileName: string) => {
        fetch(data, {
            method: 'GET'
        }).then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = fileName; // the filename you want
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
    }
    return (
        <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
            {tel && <main className="container mt-11 mb-24 lg:mb-32 ">
                <div className="max-w-4xl mx-auto">{renderContent()}</div>
            </main>}
        </div>
    );
};

export default VisaCheckResultPage;