import React from 'react';
import {fileAddTC, updateApplicantField} from '../../store/visaPageReducer';
import {useAppDispatch, useAppSelector} from '../../utils/customHooks';

type PropsType = {
    fieldName: string
    innerName?: string
    innerIndex?: number
    title: string
    index: number
    multiple?: boolean
    callback?:any
    resetCallBack?: any
    types?: string
    typesName?: string
}

const FileInput = ({title,fieldName, innerName, innerIndex, index, callback, resetCallBack,multiple,types,typesName }:PropsType) => {

    let files = useAppSelector(state => state.visa_page.applicantsData[index][fieldName])

    if (innerName && innerIndex!== undefined && innerIndex >= 0) {
        files = files[innerIndex][innerName]
    }

    const dispatch = useAppDispatch()

    const onChangeHandler = async (fieldName: string, files: FileList | null) => {
        let arr: Array<string>
        if (files) {
            let res = await dispatch(fileAddTC({files}))
            arr = res.payload as Array<string>
            dispatch(updateApplicantField({fieldName, value: arr, index}));
        }

    }
    const resetHandler = (fieldName: string) => {
        dispatch(updateApplicantField({fieldName, value: [], index}));
    }

    return (
        <div>
          <span
              className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300">{title}</span>
            <div className="mt-5 ">
                <div
                    className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                    <div className="space-y-1 text-center">
                        <svg
                            className="mx-auto h-12 w-12 text-neutral-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                        >
                            <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                        </svg>
                        <div className="flex flex-col text-sm text-neutral-6000 dark:text-neutral-300">
                            <label
                                htmlFor={fieldName + innerIndex}
                                className="relative cursor-pointer mx-auto rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                            >
                                {files.length !== 0
                                    ? <>
                                        <span>Вы загрузили {files.length} файл (-ов)</span>

                                    </>
                                    : <>
                                        <span>Загрузить файл</span>
                                    </>}
                                <input
                                    id={fieldName + innerIndex}
                                    type="file"
                                    className="sr-only"
                                    name={fieldName}
                                    multiple={multiple}
                                    accept={types || ''}
                                    onChange={(e) =>{callback ? callback(innerName || e.currentTarget.name, e.currentTarget.files, innerIndex):
                                        onChangeHandler(e.currentTarget.name, e.currentTarget.files)}

                                    }
                                />
                            </label>
                            {files.length !== 0 &&
                                <p className="cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                   onClick={() => {
                                       resetCallBack ? resetCallBack(fieldName, innerIndex) : resetHandler(fieldName)
                                   }}>
                                    Очистить
                                </p>}
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                            {typesName || 'PNG, PDF, JPG, GIF'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileInput;