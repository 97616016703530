import ModalSelectDate from "components/ModalSelectDate";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ModalReserveMobile from "./ModalReserveMobile";
import {useAppDispatch, useAppSelector} from "../../utils/customHooks";
import {setServiceFieldsAC} from "../../store/appReducer";
import {useParams} from "react-router-dom";
import moment from "moment";

const MobileFooterSticky = () => {
  const dispatch = useAppDispatch()
  const {service} = useAppSelector(state => state.app)

  const {id} = useParams()

  return (
    <div
      className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-20">
      <div className="container flex items-center justify-between">
        <div className="">
          {id === 'city-tour'
            ? <span className="text-3xl font-semibold">
            $100
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              / за человека
            </span>
          </span>
            : <span className="text-3xl font-semibold">
            $560
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              / <span className={'max-[400px]:hidden'}>Группа </span>до 10 человек
            </span>
          </span>
          }

          <ModalSelectDate
            defaultValue={service.date}
            onSelectDate={(val) => dispatch(setServiceFieldsAC({date: val}))}
            renderChildren={({defaultValue, openModal}) => (
              <span
                onClick={openModal}
                className="block text-sm underline font-medium"
              >
                {service?.date ? moment(service?.date).format('DD MMM') : 'Выберите дату'}
              </span>
            )}
          />
        </div>
        <ModalReserveMobile
          defaultGuests={service.guests}
          defaultDate={service.date}
          onChangeDate={(val) => dispatch(setServiceFieldsAC({date: val}))}
          onChangeGuests={(val) => dispatch(setServiceFieldsAC({guests: val}))}
          renderChildren={() => (
            <ButtonPrimary
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              href={'/checkout'}
            >
              Оформить
            </ButtonPrimary>
          )}
        />
      </div>
    </div>
  );
};

export default MobileFooterSticky;
