import React from 'react';
import Label from '../../../components/Label/Label';
import Input from '../../../shared/Input/Input';
import Select from '../../../shared/Select/Select';
import FormItem from '../FormItem';
import {useAppDispatch, useAppSelector} from '../../../utils/customHooks';
import {useFormik} from 'formik';
import {filedCheckTC, updateApplicantField} from '../../../store/visaPageReducer';
import {useNavigate} from 'react-router-dom';
import Button from '../../../shared/Button/Button';

type FormikErrorType = {
  fullName?: string
  male?: string
  citizenship?: string
  familyStatus?: string
  mainApplicantIs?: string
  partnerCitizenship?: string
  race?: string
}

const VisaPage1Item = ({index, isPH}: { index: number, isPH?: boolean }) => {
  const {
    fullName,
    male,
    citizenship,
    familyStatus,
    mainApplicantIs,
    partnerCitizenship,
    race
  } = useAppSelector(state => state.visa_page.applicantsData[index])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onBlurHandler = (fieldName: string, value: string, e: React.FocusEvent<HTMLInputElement | HTMLSelectElement, Element>) => {
    dispatch(updateApplicantField({fieldName, value, index}));
    formik.handleBlur(e)
  }

  const onChange = (fieldName: string, value: string, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    dispatch(updateApplicantField({fieldName, value, index}));
    formik.handleChange(e)
  }

  const formik = useFormik({
    validate: (values) => {
      const errors: FormikErrorType = {}
      if (!values.fullName) {
        errors.fullName = 'Укажите полное имя латинским алфавитом как указано в загранпаспорте'
      } else if (
        !/^[a-z\s]*$/i.test(values.fullName)) {
        errors.fullName = 'Используйте латинский алфавит'
      }
      if (!values.male) {
        errors.male = 'Укажите Ваш пол'
      }
      if (!values.citizenship) {
        errors.citizenship = 'Укажите Ваше гражданство'
      }
      if (!values.familyStatus) {
        errors.familyStatus = 'Укажите Ваше семейное положение'
      }
      if (index !== 0 && !values.mainApplicantIs) {
        errors.mainApplicantIs = 'Укажите кем Вам приходится первый заявитель'
      }
      if (values.familyStatus === 'Женат/замужем' && !values?.partnerCitizenship) {
        errors.partnerCitizenship = 'Укажите гражданство супруга/супруги'
      }
      if (!values.race) {
        errors.race = 'Выберите расу'
      }
      return errors
    },
    initialValues: {
      fullName,
      male,
      citizenship,
      familyStatus,
      mainApplicantIs,
      partnerCitizenship,
      race
    } as FormikErrorType,
    onSubmit: async (values: any) => {
      let res = await dispatch(filedCheckTC(values))
      if (res.payload) {
        navigate('/visa2')
      }
    }
  })

    return (
        <form className="listingSection__wrap max-w-full mt-6 mb-6" onSubmit={formik.handleSubmit}>

      <div>
        <div className="">
          <FormItem
            desc="Укажите полное имя латинским алфавитом как указано в загранпаспорте"
            label="Ваше полное имя">
            <Input
              className={formik.errors.fullName && formik.touched.fullName ? 'border-rose-600 mt-4' : 'mt-4'}
              type="text"
              placeholder="Ivan Ivanov"
              {...formik.getFieldProps('fullName')}
              onBlur={(e) => {
                onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
              }}
            />
            {formik.errors.fullName && formik.touched.fullName ?
              <div className="text-rose-600">{formik.errors.fullName}</div> : null}
          </FormItem>
        </div>
        <div className="mt-4">
          <Label>Раса</Label>
          <Select className={'mt-4'}
                  {...formik.getFieldProps('race')}
                  onBlur={(e) => {
                    onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                  }}
          >
            <option value="">Выберите расу</option>
            <option value="Malay">Malay</option>
            <option value="Indian">Indian</option>
            <option value="Chinese">Chinese</option>
            <option value="Caucasian">Caucasian</option>
            <option value="Another">Another</option>
          </Select>
          {formik.errors.race && formik.touched.race ?
            <div className="text-rose-600">{formik.errors.race}</div> : null}
        </div>
        <div className="flex flex-col justify-between align-top gap-2 sm:flex-row">
          <div className={'basis-1/3 mt-4'}>
            <Label>Пол</Label>
            <Select
              className={formik.errors.male && formik.touched.male ? 'border-rose-600 mt-4' : 'mt-4'}
              {...formik.getFieldProps('male')}
              onBlur={(e) => {
                onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
              }}
            >
              <option value="">Выберите пол</option>
              <option value="Мужской">Мужской</option>
              <option value="Женский">Женский</option>
            </Select>
            {formik.errors.male && formik.touched.male ?
              <div className="text-rose-600">{formik.errors.male}</div> : null}
          </div>
          <div className="mt-4 basis-2/3">
            <Label>Гражданство</Label>
            <Select
              className={formik.errors.citizenship && formik.touched.citizenship ? 'border-rose-600 mt-4' : 'mt-4'}
              {...formik.getFieldProps('citizenship')}
              onBlur={(e) => {
                onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
              }}
            >
              <option value="">Укажите гражданство</option>
              <option value="Россия">Россия</option>
              <option value="Армения">Армения</option>
              <option value="Азербайджан">Азербайджан</option>
              <option value="Беларусь">Беларусь</option>
              <option value="Грузия">Грузия</option>
              <option value="Казахстан">Казахстан</option>
              <option value="Киргизстан">Киргизстан</option>
              <option value="Молдова">Молдова</option>
              <option value="Таджикистан">Таджикистан</option>
              <option value="Туркменистан">Туркменистан</option>
              <option value="Узбекистан">Узбекистан</option>
              <option value="Не гражданин Латвии">негражданин Латвии</option>
              <option value="Не гражданин Эстонии">негражданин Эстонии</option>
            </Select>
            {formik.errors.citizenship && formik.touched.citizenship ?
              <div className="text-rose-600">{formik.errors.citizenship}</div> : null}
          </div>
        </div>
        <div className="mt-4">
          <Label>Семейное положение</Label>
          <Select
            className={formik.errors.familyStatus && formik.touched.familyStatus ? 'border-rose-600 mt-4' : 'mt-4'}
            {...formik.getFieldProps('familyStatus')}
            onChange={(e) => {
              onChange(e.currentTarget.name, e.currentTarget.value, e)
            }}
          >
            <option value="">Укажите семейное положение</option>
            <option value="Женат/замужем">Женат/замужем</option>
            <option value="Не замужем/не женат">Не замужем/не женат</option>
            <option value="Живу раздельно с супругой (-ом)">Живу раздельно с супругой (-ом)</option>
            <option value="Вдовец/вдова">Вдовец/вдова</option>
            <option value="Разведен(а)">Разведен(а)</option>
          </Select>
          {formik.errors.familyStatus && formik.touched.familyStatus ?
            <div className="text-rose-600">{formik.errors.familyStatus}</div> : null}
        </div>
        {familyStatus === 'Женат/замужем' && <div className="mt-4">
            <Label>Гражданство супруга/супруги</Label>
             <Select className={'mt-4'}
                    {...formik.getFieldProps('partnerCitizenship')}
                    onBlur={(e) => {
                      onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                    }}
            >
                <option value="">-Выберите страну-</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Aland Islands">Aland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                <option value="Botswana">Botswana</option>
                <option value="Islandt Island">Islandt Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Verdee Verde">Verdee Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, Democratic Republic of the Congo">Congo, Democratic Republic of the Congo
                </option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Curacao">Curacao</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">French Southern Territories</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-Bissau">Guinea-Bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of
                </option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kosovo">Kosovo</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the Former Yugoslav Republic
                    of
                </option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                <option value="Moldova, Republic of">Moldova, Republic of</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">Netherlands Antilles</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Barthelemy">Saint Barthelemy</option>
                <option value="Saint Helena">Saint Helena</option>
                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Martin">Saint Martin</option>
                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Serbia and Montenegro">Serbia and Montenegro</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Sint Maarten">Sint Maarten</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich
                    Islands
                </option>
                <option value="South Sudan">South Sudan</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-Leste">Timor-Leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                <option value="USSR">USSR</option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands, British">Virgin Islands, British</option>
                <option value="Virgin Islands, U.s.">Virgin Islands, U.s.</option>
                <option value="Wallis and Futuna">Wallis and Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
            </Select>
          {formik.errors.partnerCitizenship ?
            <div className="text-rose-600">{formik.errors.partnerCitizenship}</div> : null}
        </div>}

        {index !== 0 && <FormItem
            desc={'Если Вы хотите получить визу не только для себя, но и для других лиц, укажите пожалуйста, кем они Вам приходятся'}>
            <div className="mt-4">
                <Label>Заявитель приходится Вам</Label>
                <Select
                    className={formik.errors.mainApplicantIs && formik.touched.mainApplicantIs ? 'border-rose-600 mt-4' : 'mt-4'}
                    {...formik.getFieldProps('mainApplicantIs')}
                    onBlur={(e) => {
                      onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                    }}
                >
                    <option value=""></option>
                    <option value="Деловой партнер">Деловой партнер</option>
                    <option value="Друг/подруга">Друг/подруга</option>
                    <option value="Коллега">Коллега</option>
                    <option value="Ребенок">Ребенок</option>
                    <option value="Родитель">Родитель</option>
                    <option value="Сестра/Брат">Сестра/Брат</option>
                    <option value="Сожитель">Сожитель</option>
                    <option value="Супруг/Супруга">Супруг/Супруга</option>
                </Select>
              {formik.errors.mainApplicantIs && formik.touched.mainApplicantIs ?
                <div className="text-rose-600">{formik.errors.mainApplicantIs}</div> : null}
            </div>
        </FormItem>}
      </div>
      <div className="flex justify-end space-x-5 mt-8">
        <Button
          className={`ttnc-ButtonSecondary font-medium border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800`}
          href={'/visa'}>Назад
        </Button>
        <Button
          className="ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 ${className} dark:text-neutral-200"
          type="submit"
        >Продожить
        </Button>
      </div>
    </form>

    );
};

export default VisaPage1Item;
