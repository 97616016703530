import {Dialog, Transition} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/solid";
import moment from "moment";
import React, {FC, Fragment, useState} from "react";
import {
  DayPickerSingleDateController
} from "react-dates";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {useAppDispatch, useAppSelector} from "../utils/customHooks";
import {setServiceFieldsAC} from "../store/appReducer";

interface ModalSelectDateProps {
  onClose?: () => void;
  onSelectDate: (date: moment.Moment | null) => void;
  defaultValue: moment.Moment | null;
  renderChildren?: (p: {
    defaultValue: moment.Moment | null;
    openModal: () => void;
  }) => React.ReactNode;
}

const ModalSelectDate: FC<ModalSelectDateProps> = ({
                                                     defaultValue,
                                                     onClose,
                                                     onSelectDate,
                                                     renderChildren,
                                                   }) => {
  const dispatch = useAppDispatch()
  const {availableTourDates} = useAppSelector(state => state.app)
  const [showModal, setShowModal] = useState(false);
  const [focusedInput, setFocusedInput] = useState(true);

  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return renderChildren ? (
      renderChildren({defaultValue, openModal})
    ) : (
      <button onClick={openModal}>Select Date</button>
    );
  };


  const handleDateFocusChange = (arg: { focused: boolean }) => {
    setFocusedInput(arg.focused);
  };

  return (
    <>
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={closeModal}
                      >
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white"/>
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-hidden">
                      <div className="flex-1 flex flex-col items-center overflow-hidden bg-white dark:bg-neutral-800">
                        <div
                          className="flex-1 flex flex-col w-fit items-center transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <div className="p-5 ">
                            <span className="block font-semibold text-xl sm:text-2xl">
                              Выберите дату
                            </span>
                          </div>
                          <div
                            className={`flex-1 items-center relative flex z-60 overflow-hidden`}
                          >
                            <DayPickerSingleDateController
                              initialVisibleMonth={moment}
                              date={defaultValue}
                              onDateChange={(date) => {
                                onSelectDate(date);
                              }}
                              isDayBlocked={(day) => {
                                return !!availableTourDates.length ? !availableTourDates.includes(moment(day).format('DD/MM/YYYY')) : false
                              }}
                              daySize={44}
                              orientation={"horizontal"}
                              noBorder
                              onFocusChange={handleDateFocusChange}
                              hideKeyboardShortcutsPanel
                              numberOfMonths={1}
                              focused={focusedInput}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                      <button
                        type="button"
                        className="underline font-semibold flex-shrink-0"
                        onClick={() => {
                          dispatch(setServiceFieldsAC({
                            date: null,
                          }));
                        }}
                      >
                        Очистить
                      </button>
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        Сохранить
                      </ButtonPrimary>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalSelectDate;
